import { point as turfPoint, distance } from '@turf/turf';
import { getContext } from '../../../helpers/context';
import {
    fetchDataSourceBySurveyCode,
    getSelectionFeatures,
} from '../../../helpers/MapBox';

import QueryFromMap from '../../../helpers/QueryFromMap';

import {
    DataSource,
    Profile,
    Point,
    LayerLibrary,
    MapQueriedFacilitiesByUdaBySurvey,
    DatasetAbbreviationColumnName,
    MapQueryResponse,
} from '../../../types';

// Let's define necessary opportunity report surveys
const REPORT_SURVEY_METADATA: {
    [surveyCode: string]: {
        isPolygon: boolean;
        layerLibraryId?: string;
    };
} = {
    PACCOMHOUSING2020: { isPolygon: false },
};

/**
 * Calculate distance between two coordinate pairs (points) in miles,
 * using the Haversine formula. See: http://turfjs.org/docs/#distance
 *
 * @param startPoint The start point, containing lat and lng coordinates
 * @param endPoint The end point, containing lat and lng coordinates
 * @returns Distance between the start and end point, in miles, fixed to 2 decimals
 */
export const getDistance = (startPoint: Point, endPoint: Point) => {
    const origin = turfPoint([startPoint.lng, startPoint.lat]);
    const sink = turfPoint([endPoint.lng, endPoint.lat]);
    const distanceInMiles = distance(origin, sink, { units: 'miles' });
    return parseFloat(distanceInMiles.toFixed(2));
};

const getFacilities = async (
    point: Point,
    profile: Profile,
    selection: number[],
    library: LayerLibrary.Item[],
): Promise<MapQueriedFacilitiesByUdaBySurvey> => {
    const uda: MapQueriedFacilitiesByUdaBySurvey = {};
    const dataColumnsBySurveyCode: {
        [surveyCode: string]: LayerLibrary.InteractivityDataColumn[];
    } = {};
    const titleColumnBySurveyCode: {
        [surveyCode: string]: string;
    } = {};
    const tooltipColumnBySurveyCode: {
        [surveyCode: string]: string[];
    } = {};

    // Fetch data sources
    const dataSources: { [surveyCode: string]: DataSource } = {};
    await Promise.all(
        Object.keys(REPORT_SURVEY_METADATA).map(async (surveyCode: string) => {
            const dataSource = await fetchDataSourceBySurveyCode(
                surveyCode,
                library,
            );
            if (!dataSource) {
                throw new Error(
                    `DataSource for survey code ${surveyCode} couldn't be found.`,
                );
            }
            dataSources[surveyCode] = dataSource;
        }),
    );

    // Fetch selection features
    const selectionFeatures = await getSelectionFeatures(
        point,
        profile,
        selection,
    );

    const mapboxAccessToken = getContext().mapboxAccessToken
    // 1. Create the query
    const query = new QueryFromMap();
    query.setMapboxAccessToken(mapboxAccessToken);
    // 2. Add sources that will be queried from map
    Object.keys(REPORT_SURVEY_METADATA).forEach((surveyCode) => {
        const surveyMetadata = REPORT_SURVEY_METADATA[surveyCode];
        const source = dataSources[surveyCode];
        query.addSource(surveyCode, source, surveyMetadata.isPolygon);
        query.addColumns(surveyCode, query.getGeoColumnNames(surveyCode));

        // Find the library for this survey
        const layerLibrary = library.find(
            (l) =>
                l.metadata &&
                l.metadata.report &&
                l.metadata.report.surveyCode === surveyCode,
        );
        if (!layerLibrary) return uda;

        if (layerLibrary.layers[0].metadata.socialexplorer.interactivity) {
            dataColumnsBySurveyCode[surveyCode] =
                layerLibrary.layers[0].metadata.socialexplorer.interactivity.dataColumns;
            titleColumnBySurveyCode[surveyCode] =
                layerLibrary.layers[0].metadata.socialexplorer.interactivity.titleColumn;
            tooltipColumnBySurveyCode[surveyCode] =
                layerLibrary.layers[0].metadata.socialexplorer.interactivity.tooltipColumns;

            const columns: DatasetAbbreviationColumnName[] = [];

            // TODO Figure out how to find datasetAbbreviation or use a constant
            // Add title column
            columns.push({
                datasetAbbreviation: 'ORG',
                columnName: titleColumnBySurveyCode[surveyCode],
            });

            // Add regular columns
            dataColumnsBySurveyCode[surveyCode]
                .filter(
                    (column) =>
                        !column.isGoogleMapsLink &&
                        !column.isDistanceValue &&
                        !column.isTimeValue,
                )
                .forEach((column) => {
                    columns.push({
                        datasetAbbreviation: 'ORG',
                        columnName: column.id,
                    });
                });

            // Add lng lat once since they are used for several columns
            const lngLatColumn = dataColumnsBySurveyCode[surveyCode].find(
                (column) =>
                    column.isGoogleMapsLink ||
                    column.isDistanceValue ||
                    column.isTimeValue,
            );

            if (lngLatColumn) {
                columns.push({
                    datasetAbbreviation: 'ORG',
                    columnName: lngLatColumn.latColumn || '',
                });
                columns.push({
                    datasetAbbreviation: 'ORG',
                    columnName: lngLatColumn.lngColumn || '',
                });
            }

            query.addColumns(surveyCode, columns);
        }
    });

    await query.createMap('geobuffer');

    for (const surveyCode of Object.keys(REPORT_SURVEY_METADATA)) {
        uda[surveyCode] = {};
        const dataBySelectionTemp: { [selection: number]: string[] } = {};
        for (const element of selectionFeatures) {
            // Get the data
            const data = await query.fetchDataFromMap(
                surveyCode,
                element.features,
            );
            dataBySelectionTemp[element.selection] = data.map((d) =>
                JSON.stringify(d),
            );
        }
        const selection = selectionFeatures.map((element) => element.selection);
        selection.forEach((s) => {
            // Sort points by distance
            let unsortedFeatures = dataBySelectionTemp[s].map((d) =>
                JSON.parse(d),
            );
            const distanceColumn = dataColumnsBySurveyCode[surveyCode].find(
                // Find an explicit distance column, not both time and distance
                (dc) => dc.isDistanceValue && !dc.isTimeValue,
            );

            const distanceColumnName = distanceColumn
                ? distanceColumn.id
                : 'distance';

            unsortedFeatures.forEach((feature) => {
                if (
                    distanceColumn &&
                    distanceColumn.latColumn &&
                    distanceColumn.lngColumn
                ) {
                    const lat = feature[distanceColumn.latColumn];
                    const lng = feature[distanceColumn.lngColumn];
                    const sink: Point = { lng, lat };
                    const distance = getDistance(point, sink);
                    feature[distanceColumnName] = distance;
                } else {
                    feature[distanceColumnName] = Infinity;
                }
            });

            const sortedFeatures = unsortedFeatures.sort(
                (featureA: MapQueryResponse, featureB: MapQueryResponse) =>
                    featureA[distanceColumnName] - featureB[distanceColumnName],
            );

            uda[surveyCode][s] = {
                features: sortedFeatures,
                dataColumns: dataColumnsBySurveyCode[surveyCode],
                titleColumn: titleColumnBySurveyCode[surveyCode],
                tooltipColumns: tooltipColumnBySurveyCode[surveyCode],
            };
        });
    }

    query.destroyMap();
    return uda;
};

export default getFacilities;
