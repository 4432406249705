import JSZip from 'jszip';
import { MetadataConfig, ETaggedGeoJsonResponse } from '../types';

// Retrieves signed URL for protected file on S3 bucket
export const getOneTimeUseGeoJsonAccessUrl = async (
    metadata: MetadataConfig,
): Promise<string | null> => {
    const { archive } = metadata.data.file;

    // Step #1 - get signed URL for protected file on S3 bucket
    const signedUrlResponse = await fetch(
        `${process.env.REACT_APP_URL_BACKEND_RAILS}/selfstorage/points-data?fileName=${archive}`,
        {
            credentials: 'include',
        },
    );

    const urlData = await signedUrlResponse.json();
    if (!urlData || !urlData.url || !urlData.url.length) return null;
    return urlData.url;
};

export const retrieveGeoJsonWithETag = async (
    metadata: MetadataConfig,
    oneTimeUseGeoJsonAccessUrl: string,
    etag: string | null,
): Promise<ETaggedGeoJsonResponse> => {
    const { raw } = metadata.data.file;

    const dataResponse = await fetch(
        `${process.env.REACT_APP_URL_PROXY_SERVICE}?url=${encodeURIComponent(oneTimeUseGeoJsonAccessUrl)}`,
        {
            mode: 'cors',
            credentials: 'include',
            headers: etag ? { 'if-none-match': etag } : undefined,
        },
    );

    switch (dataResponse.status) {
        case 200: {
            const blob = await dataResponse.blob();
            const zip = await JSZip.loadAsync(blob);
            const rawData = await zip.file(raw);

            let geoJsonData: string | null = null;
            if (rawData) {
                geoJsonData = await rawData.async('string');
            }
            return { geojson: geoJsonData, etag: dataResponse.headers.get('etag') };
        }
        case 304: {
            return { geojson: null, etag: dataResponse.headers.get('etag') };
        }
        default: {
            throw new Error(`Unexpected error occurred: got status code ${dataResponse.status}`);
        }
    }
};
