import classNames from 'classnames';
import {
    LabelSeries,
    SectionVisualizationTable,
    SiteAnalysisBase,
} from '../../../types';
import { formatValue } from '../../../helpers/ValueFormatter';
import ValueFormat from '../../../enums/ValueFormat';

type Props = {
    data: SiteAnalysisBase;
    visualization: SectionVisualizationTable;
};
const TableSection = ({ data, visualization }: Props) => {
    const tableRows: { title: string; formattedValue: string | null }[] = [];
    visualization.labels.forEach((label: LabelSeries) => {
        const title = label.title;
        const variable = label.variable;

        const format: ValueFormat = label.format ?? visualization.valueFormat;
        let value = data[variable];
        const formattedValue = formatValue(value, format);
        tableRows.push({
            title,
            formattedValue,
        });
    });
    const style = {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
    };

    return (
        <>
            {visualization.title && (
                <h5 className="text-start mx-20 mt-36">
                    {visualization.title}
                </h5>
            )}
            <div className="my-12 border rounded-2 mx-20">
                {tableRows.map((row, idx) => {
                    const rowClasses = classNames('row mx-0 py-4 gx-0', {
                        'rounded-1': idx === 0 || idx === tableRows.length - 1,
                    });
                    const valueClasses = classNames(
                        'col-3 text-end text-black opacity-100 pe-12',
                        {
                            'fst-italic': !row.formattedValue,
                        },
                    );
                    return (
                        <div
                            key={idx}
                            className={rowClasses}
                            style={idx % 2 === 0 ? style : {}}
                        >
                            <div className="col-9 text-start opacity-062 text-black ps-12">
                                {row.title}
                            </div>
                            <div className={valueClasses}>
                                {row.formattedValue ?? 'N/A'}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default TableSection;
