import UserPopover from '../../components/UserPopover';
import { User } from '../../types';
import DashboardLogoLink from '../../components/DashboardLogoLink';
import AccessRequiredMessage from './AccessRequiredMessage';

type Props = {
    user: User;
};
const AccessRequiredPage = ({ user }: Props) => (
    <div className="container-fluid px-0">
        <div className="d-flex flex-column flex-grow-1">
            <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between bg-white px-24 py-16 shadow-sm z-index-2">
                <div className="d-flex align-items-center">
                    <div className="me-8">
                        <DashboardLogoLink />
                    </div>
                </div>
                <div className="d-flex align-items-center d-none d-lg-block">
                    <UserPopover user={user} />
                </div>
            </header>
            <AccessRequiredMessage />
        </div>
    </div>
);

export default AccessRequiredPage;
