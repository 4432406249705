import getPopOverContent from '../helpers/getDataInfoPopoverContent';

type Props = {
    visualizationIds: string[];
};

const ChartInfo = ({ visualizationIds }: Props) => (
    <>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
            tabIndex={0}
            className="btn m-0 p-0"
            role="button"
            data-bs-toggle="popover"
            data-bs-trigger="focus"
            data-bs-content={getPopOverContent(visualizationIds)}
            data-bs-html="true"
        >
            <h6 className="color-black opacity-75 fw-normal text-decoration-underline text-start link--tractiq">
                About this data
            </h6>
        </a>
    </>
);

export default ChartInfo;
