import { SelfStorage } from '../types';
import { FacilityType } from '../types';

export const SELFSTORAGE_POINT_TYPE = {
    ssFacility: 'ss-facility',
    ssConstruction: 'ss-construction',
    influencer: 'influencer',
    housingStarts: 'housing-starts',
    place: 'place',
};

/**
 * @remark FacilityType is used to store different facility type names
 *         and to represent them wherever they are needed.
 *         If there is a new facility type, just add it here like the
 *         others that are already there. **double check spelling**
 */
export const FACILITY_TYPE: FacilityType = {
    hotel: 'HOTEL',
    construction_site: 'CONSTRUCTION SITE',
    convention_center: 'CONVENTION CENTER',
    point: 'CUSTOM POINT',
};

export const FACILITY_MANAGEMENT_TYPE = {
    reit: 'REIT',
    sophisticated: 'Sophisticated',
    nonDesignated: 'Non-designated',
};

export const isSelfStoragePointTypeAllowed = (
    selfStoragePointType: string,
): selfStoragePointType is SelfStorage.PointType => {
    return Object.values(SELFSTORAGE_POINT_TYPE).includes(
        selfStoragePointType as SelfStorage.PointType,
    );
};

export const ALLOWED_POINT_TYPES = [SELFSTORAGE_POINT_TYPE.ssFacility, SELFSTORAGE_POINT_TYPE.ssConstruction];

export const isAllowedPoint = (pointType: string | null) => {
    return ALLOWED_POINT_TYPES.some(pt => pt === pointType);
};
