import { Point, Profile, SelfStorage } from '../types';
import {
    isAllowedPoint,
    isSelfStoragePointTypeAllowed,
    SELFSTORAGE_POINT_TYPE,
} from '../enums/PointType';

const SEARCH_PARAM = {
    POINT: 'point',
    PROFILE: 'profile',
    SELECTION: 'selection',
    TITLE: 'title',
    INDUSTRY: 'industry',
    PLACE_IDS: 'pois',
    POINT_TYPE: 'point-type',
    FACILITY_ID: 'facility-id',
} as const;

const PROFILES = {
    radius: 'radius',
    driving: 'driving',
    walking: 'walking',
    cycling: 'cycling',
} as const;

type ProfileKeys = keyof typeof PROFILES;
const isProfileAllowed = (profile: string): profile is ProfileKeys => {
    return Object.keys(PROFILES).includes(profile);
};

const isRingSizeAllowed = (profile: Profile, range: number): boolean => {
    switch (profile) {
        case 'radius':
            return range > 0 && range <= 20;
        case 'cycling':
        case 'driving':
        case 'walking':
            return range > 0 && range <= 60;
    }
};

// Currently every page that uses this hook has to have point, profile and selection defined in the query params
// Without these info we can not operate
const getQueryParams = (locationSearch: string) => {
    let point: Point | null = null;
    let profile: Profile;
    let selection: number[] | null = [];
    let title: string | null = null;
    let industry: string | null = null;
    let pois: string[] = [];
    let pointType: SelfStorage.PointType | null = null;
    let facilityId: string | null = null;
    const searchParams = new URLSearchParams(locationSearch);

    // get and validate first point from POIs array
    const firstPoint = searchParams
        .get(SEARCH_PARAM.PLACE_IDS)
        ?.split(',')[0] // first point: lng;lat
        .split(';') // ['lng', 'lat']
        .map(Number); // [lng, lat]

    const nanExists = firstPoint?.some((pp) => Number.isNaN(pp));
    if (firstPoint && firstPoint.length === 2 && !nanExists) {
        point = {
            lng: firstPoint[0],
            lat: firstPoint[1],
        };
    } else {
        throw new Error('Error in POIs definition');
    }

    // get and validate point type; currently used only on self storage
    const pointTypeSearchParam = searchParams.get(SEARCH_PARAM.POINT_TYPE);
    // if no point type is provided then default to coordinates
    pointType = (pointTypeSearchParam ||
        SELFSTORAGE_POINT_TYPE.place) as SelfStorage.PointType;
    if (!isSelfStoragePointTypeAllowed(pointType)) {
        // If we are dealing with an unknown point type, default to coordinates
        pointType = SELFSTORAGE_POINT_TYPE.place as SelfStorage.PointType;
    }
    if (isAllowedPoint(pointTypeSearchParam)) {
        facilityId = searchParams.get(SEARCH_PARAM.FACILITY_ID);
    }

    // get and validate profile
    const profileParam = searchParams.get(SEARCH_PARAM.PROFILE);
    if (profileParam && isProfileAllowed(profileParam)) {
        profile = PROFILES[profileParam];
    } else {
        throw new Error('Wrong profile provided');
    }

    // get and validate selection
    const selectionParams = searchParams
        .get(SEARCH_PARAM.SELECTION) // '1,5,3,5,zzz'
        ?.split(',') // ['1', '5', '3', '5', 'zzz']
        .map(Number) // [1, 5, 3, 5, NaN]
        .filter((s) => !Number.isNaN(s)) // [1, 5, 3, 5]
        .sort((s1, s2) => s1 - s2); // [1, 3, 5, 5]
    const uniqueSelection = new Set(selectionParams);

    if (
        !selectionParams ||
        !selectionParams.length ||
        uniqueSelection.size > 3
    ) {
        throw new Error('Error in selection definition');
    } else {
        selection = Array.from(uniqueSelection);
    }

    // check if values are within the allowed range
    if (selection.some((ring) => !isRingSizeAllowed(profile, ring))) {
        throw new Error(`Ring size for profile "${profile}" is out or range`);
    }

    const titleParam = searchParams.get(SEARCH_PARAM.TITLE);
    if (titleParam) {
        title = titleParam;
    }

    const industryParam = searchParams.get(SEARCH_PARAM.INDUSTRY);
    if (industryParam) {
        industry = decodeURIComponent(industryParam);
    }

    // get place IDs
    const placeIdsParam = searchParams
        .get(SEARCH_PARAM.PLACE_IDS) // 'hd1629059,hd1717518'
        ?.split(','); // ['hd1629059','hd1717518']
    const uniquePlaceIds = new Set(placeIdsParam);

    if (!placeIdsParam || !placeIdsParam.length) {
        throw new Error('POIs are undefined!');
    } else if (uniquePlaceIds.size > 7) {
        throw new Error(`Maximum number of selected points for analysis is 7.`);
    } else {
        pois = Array.from(uniquePlaceIds);
    }

    return {
        point,
        profile,
        selection,
        title,
        industry,
        pois,
        pointType,
        facilityId,
    };
};

export default getQueryParams;
