import Highcharts from 'highcharts';

import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { isChartEmpty } from './getChartOptions';

const WIDTH = 1040;
const HEIGHT = 400;

const svgTob64 = (svgString: string) => {
    const b64 = 'data:image/svg+xml;base64,';
    // https://developer.mozilla.org/en/DOM/window.btoa
    const svgDataUrl = `${b64}${btoa(unescape(encodeURIComponent(svgString)))}`;
    return svgDataUrl;
};

export const getChartInstance = (visualizationId: string) => {
    const container = document.getElementById(visualizationId);
    const chartIndex = container?.getAttribute('data-highcharts-chart');
    if (chartIndex) {
        return Highcharts.charts[+chartIndex];
    }
    return null;
};

export const getChartImage = (
    visualizationId: string,
    visualizationExportLabel: string,
) => {
    return new Promise<{ pngData: string; src: string }>((resolve, reject) => {
        const chartInstance = getChartInstance(visualizationId);
        if (chartInstance) {
            // Get svg
            // NOTE: getSVG does not use the options provided to chartInstance.
            // Instead, it uses (some of) the default chart options, unless stated otherwise.
            const svgString = chartInstance.getSVG({
                exporting: {
                    enabled: true,
                    allowHTML: true,
                },
                chart: {
                    width: WIDTH,
                    height: HEIGHT,
                    spacingLeft: 48,
                    spacingRight: 48,
                    // classes dont work on export, so we cant use className attribute like we did in getChartOptions.ts
                    backgroundColor: isChartEmpty(chartInstance.series)
                        ? '#F5F5F5'
                        : '#FFFFFF',
                },
                title: {
                    text: visualizationExportLabel,
                    align: 'left',
                    style: { color: '#666666', fontSize: '18px' },
                },
                legend: {
                    itemDistance: 40, // TODO: !!! workaround, needs to be fixed later !!!
                },
                lang: {
                    noData: 'No data for selected area.',
                },
                noData: {
                    useHTML: false,
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030',
                    },
                },
            });
            const b64 = svgTob64(svgString);
            const image = new Image();
            // Create canvas and use draw image on that.
            const canvas = document.createElement('canvas');
            // get canvas context for drawing on canvas
            const context = canvas.getContext('2d');
            canvas.width = WIDTH;
            canvas.height = HEIGHT;
            image.onload = function () {
                if (context) {
                    // clear canvas
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    // make canvas white
                    context.fillStyle = 'white';
                    context.fillRect(0, 0, canvas.width, canvas.height);
                    // draw image with SVG data to canvas
                    context.drawImage(image, 0, 0, WIDTH, HEIGHT);
                    // snapshot canvas as png
                    const pngData = canvas.toDataURL('image/png');
                    const src = pngData.substr(pngData.indexOf(',') + 1);
                    resolve({ pngData, src });
                }
            };
            image.src = b64;
        }
    });
};

/**
 * Returns PNG image of a single chart
 *
 * @param visualizationId the unique ID of the chart
 * @param visualizationLabel name of .png file that gets downloaded
 * @param ring if we have rings (for demography and opportunity), this gets added
 *             to the filename, to know what ring produced this output
 * @returns PNG of chart
 */
export const singleExport = async (
    visualizationId: string,
    visualizationLabel: string,
    ring: number | null = null,
) => {
    const call = getChartImage(visualizationId, visualizationLabel);
    const { src } = await call;
    // decode b64 string
    const byteCharacters = atob(src);
    // turn characters into ASCII values
    const byteNumbers = byteCharacters
        .split('')
        .map((char) => char.charCodeAt(0));
    // blob ctor expects this type
    const byteArray = new Uint8Array(byteNumbers);
    // create blob
    const imgFile = new Blob([byteArray], {
        type: 'image/png',
    });
    // save
    const fileName =
        ring == null ? visualizationLabel : `${visualizationLabel}-${ring}`;
    saveAs(imgFile, fileName);
};

/**
 * Returns zip archive with multiple PNG images of charts
 *
 * @param calls array of promises that return the images
 * @param fileNames array of strings with names for each image file
 * @param archiveName string which represents the name of the returned zip archive
 * @returns a zip archive with multiple chart images
 */
export const multiExport = async (
    calls: Promise<{ pngData: string; src: string }>[],
    fileNames: string[],
    archiveName: string,
) => {
    const zip = new JSZip();
    const imageSources = await Promise.all(calls);

    imageSources.forEach(({ src }, index) => {
        zip.file(`${fileNames[index]}.png`, src, {
            base64: true,
        });
    });
    // Download the file
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${archiveName}.zip`);
    });
};

/**
 * 
 * @param visualizationId DOM id of chart which we want to export to SVG
 * @param exportOptions Export options for chart
 * @returns SVG of chart, if chart exists. Otherwise return undefined
 * @remarks If we want to perserve all the options which are already
 * in the chart (that is, if we do not want to make any changes), we can
 * simply pass an empty object as exportOptions (check out Util.ts, where this case happens)
 */
export const getChartAsSvgString = (
    visualizationId: string,
    exportOptions: Highcharts.Options,
) => {
    const chartInstance = getChartInstance(visualizationId);
    if (chartInstance) {
        return chartInstance.getSVG(exportOptions);
    }
    return undefined;
};
