import { MetadataConfig } from '../types';

let configJson: MetadataConfig;

/**
 * A function that fetches the metadata json file form Dingo maps
 *
 * The file is retrieved from the network only once, and then cached. The following
 * calls will get cached version.
 *
 * @returns a Promise that will be resolved as a content of library.json
 */
const getMetadataJson = async () => {
    if (!configJson) {
        const response = await fetch(
            `${process.env.REACT_APP_URL_DINGO_MAPS_METADATA}/selfstorage.json`,
        );
        configJson = await response.json();
    }
    return configJson;
};

export default getMetadataJson;
