import {
    Options,
    Series,
    SeriesAreaOptions,
    SeriesBarOptions,
    SeriesColumnOptions,
    SeriesPieOptions,
    XAxisOptions,
} from 'highcharts';
import {
    SectionVisualizationChart,
    VisualizationSeries,
    SiteAnalysisBase,
} from '../types';
import {
    getDataLabelFormatter,
    getTooltipPointFormatter,
} from './ValueFormatter';
import ValueFormat from '../enums/ValueFormat';
import Highcharts from 'highcharts';

/**
 *
 * @param x
 * @param y
 * @param w
 * @param h
 * @returns SVG path for download icon
 * @remark M, x, y -> move cursor to (x,y);
 *         L, x, y -> draw line from current cursor position to (x,y)
 */
Highcharts.SVGRenderer.prototype.symbols.download = function (
    x: number,
    y: number,
    w: number,
    h: number,
) {
    const path = [
        // Arrow stem
        'M',
        x + w * 0.5,
        y,
        'L',
        x + w * 0.5,
        y + h * 0.7,
        // Arrow head
        'M',
        x + w * 0.3,
        y + h * 0.5,
        'L',
        x + w * 0.5,
        y + h * 0.7,
        'L',
        x + w * 0.7,
        y + h * 0.5,
        // Box
        'M',
        x,
        y + h * 0.9,
        'L',
        x,
        y + h,
        'L',
        x + w,
        y + h,
        'L',
        x + w,
        y + h * 0.9,
    ];
    return path;
};

/**
 * Gets color of chart label depending on data
 *
 * @param data - data received from backend
 * @param variables - variables used for visualization
 * @param visualization - info about visualization
 * @returns - a string, either 'contrast' or 'black'
 *
 * @remarks
 *
 * If at least one value is zero, we make the labels black.
 * This is currently only used for bar charts, since empty
 * bars would have a white zero as a label on a white background,
 * and the number zero would be invisible. 'contrast' is the default
 * value for the color attribute - this lets Highcharts pick the best
 * possible color depending on the underlying color
 */
const getChartLabelColor = (
    data: SiteAnalysisBase,
    visualization: SectionVisualizationChart,
): string => {
    let color = 'contrast';
    visualization.series.forEach((series) => {
        series.data.forEach((d) => {
            const y = data[d.value];
            if (y === 0) color = 'black';
        });
    });
    return color;
};

const getParsedSeriesData = (
    data: SiteAnalysisBase,
    series: VisualizationSeries,
    visualization: SectionVisualizationChart,
): Array<
    string | number | null | [number | string, string | number | null]
> => {
    const transformedSeriesData: Array<
        string | number | null | [number | string, string | number | null]
    > = [];

    if (!data) {
        return [];
    }
    let allNulls = true;
    series.data.forEach((d) => {
        const y = data[d.value];
        if (visualization.isStacked) {
            transformedSeriesData.push(y !== undefined ? y : null);
        } else {
            transformedSeriesData.push([
                d.category,
                y !== undefined ? y : null,
            ]);
        }
        // If at least one data point is not null or undefined,
        // set flag to false
        if (y !== null && y !== undefined) allNulls = false;
    });
    // If flag is still true, y was always null or undefined
    // return [] as data, to trigger Highcharts NoData display
    if (allNulls) return [];
    return transformedSeriesData;
};

const getSeries = (
    visualization: SectionVisualizationChart,
    data: SiteAnalysisBase,
): Array<
    | SeriesAreaOptions
    | SeriesBarOptions
    | SeriesColumnOptions
    | SeriesPieOptions
> => {
    return visualization.series.map((series) => {
        const commonSeriesOptions = {
            name: series.name,
            color: series.color,
            data: getParsedSeriesData(data, series, visualization),
        };

        switch (visualization.chartType) {
            case 'bar':
                return {
                    type: 'bar',
                    ...commonSeriesOptions,
                } as SeriesBarOptions;
            case 'pie':
                return {
                    type: 'pie',
                    ...commonSeriesOptions,
                } as SeriesPieOptions;
            case 'area':
                return {
                    type: 'area',
                    zones: series.zones,
                    zoneAxis: series.zones ? 'x' : undefined,
                    ...commonSeriesOptions,
                } as SeriesAreaOptions;
            default:
                return {
                    type: 'column',
                    ...commonSeriesOptions,
                } as SeriesColumnOptions;
        }
    });
};

const getYAxis = (visualization: SectionVisualizationChart) => {
    const visible = visualization.chartType === 'bar' ? false : true;
    return {
        visible,
        labels: {
            style: {
                fontSize: '13px',
            },
            formatter: function (
                this: Highcharts.AxisLabelsFormatterContextObject,
            ) {
                const label = this.axis.defaultLabelFormatter.call(this);
                switch (visualization.valueFormat) {
                    case ValueFormat.FORMAT_CURRENCY_2_DECIMAL:
                        return `$${label}`;
                    case ValueFormat.FORMAT_PERCENT_2_DECIMAL:
                        return `${label}%`;
                    default:
                        return label;
                }
            },
        },
        title: {
            text: undefined,
        },
        maxPadding: 0.1,
    };
};

/**
 * Check if a chart is empty
 *
 * @param seriesArray - the chart series, consisting of multiple individual series
 * @returns - true if every element in seriesArray is empty or null
 */
export const isChartEmpty = (
    seriesArray: (
        | SeriesAreaOptions
        | SeriesBarOptions
        | SeriesColumnOptions
        | SeriesPieOptions
        | Series
    )[],
) => {
    return seriesArray.every(
        (series) => series.data == null || series.data.length === 0,
    );
};

export const getChartOptions = (
    visualization: SectionVisualizationChart,
    data: SiteAnalysisBase,
    exportTitleSuffix: string,
): Options => {
    const series = getSeries(visualization, data);
    const chartLegend = visualization.legend ?? {
        enabled: visualization.hasLegend,
        // For stacked column charts, there is no item hover style change
        itemHoverStyle: visualization.isStacked
            ? { cursor: 'default', color: '#333333' }
            : { color: '#000000' },
    };
    return {
        chart: {
            height: visualization.height,
            type: visualization.chartType,
            animation: false,
            spacingLeft: 0,
            spacingRight: 0,
            spacingBottom: 20,
            className: isChartEmpty(series) ? 'empty-chart' : undefined,
            style: {
                fontFamily: '"Source Sans Pro", sans-serif',
            },
        },
        lang: {
            noData: 'No data for selected area.',
        },
        noData: {
            useHTML: false,
            style: {
                fontWeight: 'bold',
                fontSize: '15px',
                color: '#303030',
            },
        },
        credits: {
            enabled: false,
        },
        title: {
            text: undefined,
        },
        tooltip: {
            enabled: visualization.hasTooltip || false,
            shared: false,
            backgroundColor: '#FFFFFF',
            borderWidth: 0,
            shape: 'square',
            headerFormat:
                '<span style="font-size: 12px">{point.key}</span><br/>',
            style: {
                fontSize: '14px',
            },
            pointFormatter: getTooltipPointFormatter(visualization.valueFormat),
        },
        exporting: {
            filename: `${
                visualization.title === ''
                    ? visualization.header
                    : visualization.title
            } ${exportTitleSuffix}`,
            enabled: true,
            chartOptions: {
                chart: {
                    spacingLeft: 60,
                    spacingRight: 60,
                    spacingTop: 30,
                    spacingBottom: 30,
                },
            },
            buttons: {
                contextButton: {
                    symbol: 'download',
                    menuItems: ['downloadPNG', 'downloadSVG'],
                    // For some reason the following property is not recognized, but it works!
                    // Doc reference: https://api.highcharts.com/highcharts/exporting.buttons.contextButton.symbolStrokeWidth
                    //@ts-ignore
                    symbolStrokeWidth: 2.5,
                    align: 'right',
                    verticalAlign: 'bottom',
                    x: 0,
                    y: 22,
                },
            },
        },
        legend: chartLegend,
        yAxis: getYAxis(visualization),
        xAxis: {
            lineWidth: 0,
            type: 'category',
            labels: {
                style: {
                    fontSize: '13px',
                },
                padding: 0,
            },
            margin: 0,
            categories: series.map((s) => s.name),
            ...visualization.xAxis,
        } as XAxisOptions,
        plotOptions: {
            series: {
                animation: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
            },
            bar: {
                stacking: visualization.hasStacking ? 'normal' : undefined,
                groupPadding: visualization.groupPadding ?? 0.01,
                pointPadding: visualization.pointPadding ?? 0,
                // maxPointWidth: 48,
                grouping: visualization.isGrouped
                    ? visualization.isGrouped
                    : false,
                dataLabels: {
                    enabled: true,
                    align: 'left',
                    style: {
                        textOutline: '0px',
                        fontSize: '13px',
                        fontWeight: 'normal',
                        color: getChartLabelColor(data, visualization),
                    },
                    formatter: getDataLabelFormatter(visualization.valueFormat),
                },
            },
            column: {
                stacking: visualization.isStacked ? 'percent' : undefined,
                groupPadding: 0.01,
                pointPadding: 0,
                grouping: false,
                // For stacked column charts, prevent clicking of legend
                events: {
                    legendItemClick: function (e) {
                        if (visualization.isStacked) {
                            e.preventDefault();
                        }
                    },
                },
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point) {
                        const percent = this.percentage ?? 0;
                        const yVal = this.y ?? NaN;
                        const symbol = '●';
                        return `<span style="font-size: 14px"> <span style="color: ${
                            this.color
                        }"> ${symbol} </span> ${this.series.name}: <b>${
                            Math.round(percent * 100) / 100
                        }%</b> (${yVal.toLocaleString()})</span>`;
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        textOutline: '0px',
                        fontSize: '13px',
                        fontWeight: 'normal',
                        color: '#666666', // TODO: Find out if we need this line.
                    },
                    formatter: getDataLabelFormatter(visualization.valueFormat),
                },
            },
            area: {
                fillOpacity: 0.12,
                marker: {
                    enabled: false,
                },
                dataLabels: {
                    enabled: false,
                },
                states: {
                    hover: {
                        enabled: true,
                        halo: {
                            size: 0,
                        },
                    },
                },
            },
            pie: {
                allowPointSelect: false,
                cursor: 'default',
                colors: ['#EC9A71', '#E0486C', '#674B69', '#E7DEC7'],
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.percentage:.1f}%',
                    style: {
                        fontSize: '13px',
                    },
                },
                tooltip: {
                    pointFormatter: function (this: Highcharts.Point) {
                        const percent = this.percentage ?? 0;
                        const yVal = this.y ?? NaN;
                        const symbol = '●';

                        return `<span style="font-size: 14px;"> <span style="color: ${
                            this.color
                        }; bottom: 32px"> ${symbol} </span> ${
                            this.series.name
                        }: <b>${
                            Math.round(percent * 100) / 100
                        }%</b> (${yVal.toLocaleString()})</span>`;
                    },
                },
                borderWidth: 0,
            },
        },
        series,
    };
};
