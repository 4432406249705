import { generatePath, Link, useLocation } from 'react-router-dom';
import { REPORTS } from '../../enums/Reports';
import useRouteParams from '../../hooks/useRouteParams';

const DiveDeeperSection = () => {
    const { search } = useLocation();
    const { frame } = useRouteParams();
    const getPath = (basePath: string) => {
        let path = generatePath(basePath, {
            frame,
        });
        // attach search params to path
        path += search;
        return path;
    };

    const bgStyle = {
        backgroundColor: 'rgba(18, 87, 221, 0.07)',
    };
    return (
        <div className="container pt-32 mb-28">
            <div className="row gx-8">
                <div className="col-auto">
                    <i className="text-tractiq material-icons">auto_graph</i>
                </div>
                <div className="col-auto">
                    <h5>Dive deeper into...</h5>
                </div>
            </div>
            <div className="row mx-4 mt-16">
                <Link
                    className="text-decoration-none text-black mx-0 px-0"
                    to={getPath(REPORTS.pricing.basePath)}
                >
                    <div
                        className="col border rounded px-16 py-16 pb-20 on-hover"
                        style={bgStyle}
                        role="button"
                    >
                        <div className="row text-start">
                            <h4>Rate Trends</h4>
                        </div>
                        <div className="row text-start mt-8">
                            <div className="body-2">
                                Look at historical trends in average pricing
                                rates per square foot over the past 4 years for
                                common unit types.
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="row mx-4 mt-12">
                <Link
                    className="text-decoration-none text-black mx-0 px-0 w-50"
                    to={getPath(REPORTS.demography.basePath)}
                >
                    <div
                        className="col border rounded px-16 py-16 pb-20 me-6 on-hover"
                        style={bgStyle}
                        role="button"
                    >
                        <div className="row text-start">
                            <h4>Demography</h4>
                        </div>
                        <div className="row text-start mt-8">
                            <div className="body-2">
                                Understand Population, Households, Income, and
                                Median Rent. Current and Projected.
                            </div>
                        </div>
                    </div>
                </Link>
                <Link
                    className="text-decoration-none text-black mx-0 px-0 w-50"
                    to={getPath(REPORTS.opportunity.basePath)}
                >
                    <div
                        className="col border rounded px-16 py-16 pb-20 ms-6 on-hover"
                        style={bgStyle}
                        role="button"
                    >
                        <div className="row text-start">
                            <h4>Opportunity</h4>
                        </div>
                        <div className="row text-start mt-8">
                            <div className="body-2">
                                Population Growth Projections, Square Footage
                                Projections, and Competition.
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default DiveDeeperSection;
