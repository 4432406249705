import { MapJsonResponse } from '../types';

/**
 * Function that fetches a map .json file
 *
 * @returns Promise that will be resolved as a content of <surveyCode>.json
 */
const getMapJson = async (surveyCode: string) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_URL_DINGO_MAPS}/${surveyCode}.json`,
        );
        const surveyMap: MapJsonResponse.MapJson = await response.json();
        return surveyMap;
    } catch (error) {
        throw new Error('Could not retrieve the map JSON file.');
    }
};

export default getMapJson;
