import { point as turfPoint, distance } from '@turf/turf';
import ValueFormat from '../../../enums/ValueFormat';
import { getContext } from '../../../helpers/context';
import { getSelectionFeatures } from '../../../helpers/MapBox';

import QueryFromMap from '../../../helpers/QueryFromMap';

import {
    Profile,
    Point,
    LayerLibrary,
    MapQueriedFacilitiesByUdaBySurvey,
    MetadataConfig,
    MapQueryResponse,
} from '../../../types';

// Let's define necessary opportunity report surveys
const REPORT_SURVEY_METADATA: {
    [surveyCode: string]: {
        isPolygon: boolean;
        layerLibraryId?: string;
    };
} = {
    PACCOM2020: { isPolygon: false },
};

/**
 * Calculate distance between two coordinate pairs (points) in miles,
 * using the Haversine formula. See: http://turfjs.org/docs/#distance
 *
 * @param startPoint The start point, containing lat and lng coordinates
 * @param endPoint The end point, containing lat and lng coordinates
 * @returns Distance between the start and end point, in miles, fixed to 2 decimals
 */
export const getDistance = (startPoint: Point, endPoint: Point) => {
    const origin = turfPoint([startPoint.lng, startPoint.lat]);
    const sink = turfPoint([endPoint.lng, endPoint.lat]);
    const distanceInMiles = distance(origin, sink, { units: 'miles' });
    return parseFloat(distanceInMiles.toFixed(2));
};

const getGeoJsonPoints = async (
    point: Point,
    profile: Profile,
    selection: number[],
    geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
    metadata: MetadataConfig,
): Promise<MapQueriedFacilitiesByUdaBySurvey> => {
    const uda: MapQueriedFacilitiesByUdaBySurvey = {};
    const dataColumnsBySurveyCode: {
        [surveyCode: string]: LayerLibrary.InteractivityDataColumn[];
    } = {};
    const titleColumnBySurveyCode: {
        [surveyCode: string]: string;
    } = {};
    const tooltipColumnBySurveyCode: {
        [surveyCode: string]: string[];
    } = {};

    // Fetch selection features
    const selectionFeatures = await getSelectionFeatures(
        point,
        profile,
        selection,
    );

    const mapboxAccessToken = getContext().mapboxAccessToken;
    // 1. Create the query
    const query = new QueryFromMap();
    query.setMapboxAccessToken(mapboxAccessToken);
    // 2. Add geojson sources
    for (const surveyCode of Object.keys(REPORT_SURVEY_METADATA)) {
        query.setGeoJsonSources(surveyCode, {
            type: 'geojson',
            data: geojson,
        });
        query.setGeoJsonLayers(surveyCode, `facilities_${surveyCode}`);
    }

    await query.createMap('geojson');

    for (const surveyCode of Object.keys(REPORT_SURVEY_METADATA)) {
        uda[surveyCode] = {};
        const dataBySelectionTemp: { [selection: number]: string[] } = {};
        for (const element of selectionFeatures) {
            // Get the data
            const data = await query.fetchGeoJsonDataFromMap(
                surveyCode,
                element.features,
            );
            dataBySelectionTemp[element.selection] = data.map((d) => {
                const temp = JSON.parse(JSON.stringify(d));
                const lng = temp.geometry.coordinates[0];
                const lat = temp.geometry.coordinates[1];
                temp.properties['lng'] = lng;
                temp.properties['lat'] = lat;
                return JSON.stringify(temp.properties);
            });
        }
        const dataColumns: LayerLibrary.InteractivityDataColumn[] = Object.keys(
            metadata.properties,
        ).map((key) => {
            const obj = metadata.properties[key];
            return {
                id: key,
                title: obj.label,
                formatting: obj.format ?? ValueFormat.FORMAT_NONE,
                isLink: obj.isWebsite ?? false,
                linkTitle: obj.isWebsite ? obj.label : '',
            };
        });

        // add lat and lng data columns
        dataColumns.push({
            id: 'lat',
            title: 'Latitude',
            formatting: ValueFormat.FORMAT_NUMBER_5_DECIMAL.toString(),
            isLink: false,
            linkTitle: '',
        });
        dataColumns.push({
            id: 'lng',
            title: 'Longitude',
            formatting: ValueFormat.FORMAT_NUMBER_5_DECIMAL.toString(),
            isLink: false,
            linkTitle: '',
        });
        // add google maps column
        dataColumns.push({
            id: 'googleMapsLink',
            title: '',
            formatting: ValueFormat.FORMAT_NONE.toString(),
            isLink: true,
            isGoogleMapsLink: true,
            linkTitle: 'Open in Google maps',
            latColumn: 'lat',
            lngColumn: 'lng',
        });
        // add drive time column
        dataColumns.push({
            id: 'driveTime',
            title: '',
            formatting: ValueFormat.FORMAT_NONE.toString(),
            isTimeValue: true,
            latColumn: 'lat',
            lngColumn: 'lng',
        });
        // add distance from pin column
        dataColumns.push({
            id: 'distance',
            title: 'Distance',
            formatting: ValueFormat.FORMAT_NONE.toString(),
            isDistanceValue: true,
            isTimeValue: false,
            latColumn: 'lat',
            lngColumn: 'lng',
        });
        dataColumnsBySurveyCode[surveyCode] = dataColumns;
        // add title column by survey
        titleColumnBySurveyCode[surveyCode] = 'name';
        tooltipColumnBySurveyCode[
            surveyCode
        ] = metadata.popupProperties.propertiesForPointType.default.flatMap(
            (prop: string | string[]) => prop,
        );
        const selection = selectionFeatures.map((element) => element.selection);

        selection.forEach((s) => {
            // Sort points by distance
            let unsortedFeatures = dataBySelectionTemp[s].map((d) =>
                JSON.parse(d),
            );
            const distanceColumn = dataColumnsBySurveyCode[surveyCode].find(
                // Find an explicit distance column, not both time and distance
                (dc) => dc.isDistanceValue && !dc.isTimeValue,
            );

            const distanceColumnName = distanceColumn
                ? distanceColumn.id
                : 'distance';

            unsortedFeatures.forEach((feature) => {
                if (
                    distanceColumn &&
                    distanceColumn.latColumn &&
                    distanceColumn.lngColumn
                ) {
                    const lat = feature[distanceColumn.latColumn];
                    const lng = feature[distanceColumn.lngColumn];
                    const sink: Point = { lng, lat };
                    const distance = getDistance(point, sink);
                    feature[distanceColumnName] = distance;
                } else {
                    feature[distanceColumnName] = Infinity;
                }
            });

            const sortedFeatures = unsortedFeatures.sort(
                (featureA: MapQueryResponse, featureB: MapQueryResponse) =>
                    featureA[distanceColumnName] - featureB[distanceColumnName],
            );

            uda[surveyCode][s] = {
                features: sortedFeatures,
                dataColumns: dataColumnsBySurveyCode[surveyCode],
                titleColumn: titleColumnBySurveyCode[surveyCode],
                tooltipColumns: tooltipColumnBySurveyCode[surveyCode],
            };
        });
    }

    query.destroyMap();
    return uda;
};

export default getGeoJsonPoints;
