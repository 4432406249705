import { useContext } from 'react';
import { AnalysisProfile } from '../../enums/AnalysisProfile';
import useQueryParams from '../../hooks/useQueryParams';
import { Context } from '../../helpers/context';

const OpenInMap = () => {
    const { point, profile, selection, title } = useQueryParams();
    const { mapUrl } = useContext(Context);

    const onClick = () => {
        const params: {
            [param: string]: string;
        } = {
            selectedValues: JSON.stringify(selection),
            selectedType: AnalysisProfile[profile].dingoProfile,
            // mock HERE search results (only necessary properties are defined)
            searchResults: JSON.stringify({
                address: {
                    label: title,
                },
                position: {
                    lat: point.lat,
                    lng: point.lng,
                },
            }),
        };
        // Create hidden post form
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = mapUrl;
        form.target = '_blank';
        Object.keys(params).forEach((key) => {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = params[key];
            form.appendChild(hiddenField);
        });
        document.body.appendChild(form);
        form.submit();
        form.remove();
    };

    return (
        <button
            type="button"
            className="btn px-0 opacity-75 fw-bold d-none d-md-flex justify-content-end"
            onClick={onClick}
        >
            Open in Map
            <i className="material-icons ms-4">open_in_new</i>
        </button>
    );
};

export default OpenInMap;
