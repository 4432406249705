import { FacilitySizeFilter, FacilityCommonFilter } from '../../../types';

export const SIZES = ['5x5', '5x10', '10x10', '10x15', '10x20', '10x30'];
export const CHUNK_MULTIPLIERS = [25, 50, 100, 150, 200, 300];
export const FACILITY_TYPES: {
    size: keyof FacilityCommonFilter;
    multiplier: number;
}[] = [
    { size: '5x5' as keyof FacilityCommonFilter, multiplier: 25 },
    { size: '5x10' as keyof FacilityCommonFilter, multiplier: 50 },
    { size: '10x10' as keyof FacilityCommonFilter, multiplier: 100 },
    { size: '10x15' as keyof FacilityCommonFilter, multiplier: 150 },
    { size: '10x20' as keyof FacilityCommonFilter, multiplier: 200 },
    { size: '10x30' as keyof FacilityCommonFilter, multiplier: 300 },
];
//@ts-ignore
export const FIELDS_TO_MAP: [keyof FacilitySizeFilter] = ['avg', 'max', 'min'];
export enum UnitType {
    NonClimateController = 'nonClimateControlled',
    ClimateControlled = 'climateControlled',
    All = 'all',
}
export const UNIT_TYPES = [
    { id: UnitType.All, label: 'All units' },
    { id: UnitType.ClimateControlled, label: 'Climate Controlled (CC)' },
    { id: UnitType.NonClimateController, label: 'Non-Climate Controlled (NCC)' },
] as const;
