import { createContext } from 'react';

export const defaultContext = {
    mapUrl: '#',
    loginUrl: '#',
    dashboardUrl: '#',
    mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
};

export const getContext = () => {
    return {
        mapUrl: process.env.REACT_APP_URL_MAP_SELFSTORAGE,
        loginUrl: process.env.REACT_APP_URL_RAILS_LOGIN_SELFSTORAGE,
        dashboardUrl: process.env.REACT_APP_URL_RAILS_DASHBOARD_SELFSTORAGE,
        mapboxAccessToken:
            process.env.REACT_APP_SELFSTORAGE_MAPBOX_ACCESS_TOKEN,
    };
};

export const Context = createContext(defaultContext);
