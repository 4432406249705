import { useLocation, generatePath, matchPath } from 'react-router';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { REPORTS } from '../enums/Reports';
import useRouteParams from '../hooks/useRouteParams';
import useQueryParams from '../hooks/useQueryParams';

import { Report } from '../types';
import { SELFSTORAGE_POINT_TYPE } from '../enums/PointType';

type Props = {
    mode?: 'dropdown' | 'nav';
};
const ReportSwitcher = ({ mode }: Props) => {
    const { pathname, search } = useLocation();
    const { frame } = useRouteParams();
    const { pointType } = useQueryParams();

    // Filter reports based on pointType
    const availableReports = Object.keys(REPORTS)
        .filter((reportKey) => {
            // If we're viewing area based on point coordinates (not a facility
            // or another type of object), then we do not show the Point Info report
            if (
                pointType === SELFSTORAGE_POINT_TYPE.place &&
                reportKey === 'selfStoragePointInfo'
            ) {
                return false;
            }

            return true;
        })
        .map((reportKey: string): Report => REPORTS[reportKey as keyof object]);

    const activeReport = availableReports.find((report) => {
        const match = matchPath(pathname, {
            path: report.basePath,
        });
        return !!match;
    });

    const getPath = (basePath: string) => {
        let path = generatePath(basePath, {
            frame,
        });
        // attach search params to path
        path += search;
        return path;
    };

    const dropdownClasses = classNames('col-auto dropdown mx-4', {
        'd-block': mode === 'dropdown',
        'd-block d-lg-none': mode === undefined,
        'd-none': mode === 'nav',
    });
    const navTabsClasses = classNames('col-auto mx-4', {
        'd-none': mode === 'dropdown',
        'd-none d-lg-block': mode === undefined,
        'd-block': mode === 'nav',
    });

    return (
        <div className="m-0 p-0 my-16 mx-8 me-0 mx-md-32 gx-8 d-flex align-items-center justify-content-center">
            <div className="col-auto text-black opacity-062 px-4">
                Get insights into
            </div>
            <div className={dropdownClasses}>
                <button
                    className="btn btn--filter border px-12 me-8 text-tractiq fw-bold dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-offset="0,4"
                >
                    <div className="d-inline pe-4">{activeReport?.label}</div>
                </button>
                <ul className="dropdown-menu p-0 shadow-4 radius-16 mx-24">
                    {availableReports.map(({ label, basePath }, idx) => (
                        <li key={label}>
                            <Link
                                className={classNames(
                                    'dropdown-item dropdown-item--tractiq px-24 py-8 mb-16',
                                    {
                                        'mt-24': idx === 0,
                                        'dropdown-item--tractiq-active':
                                            basePath === activeReport?.basePath,
                                    },
                                )}
                                to={getPath(basePath)}
                            >
                                {label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={navTabsClasses}>
                <ul className="nav nav-tabs">
                    {availableReports.map(({ label, basePath }) => (
                        <li key={label} className="nav-item">
                            <Link
                                type="button"
                                className={classNames('nav-link ps-16 pe-16', {
                                    active: basePath === activeReport?.basePath,
                                    'text-tractiq':
                                        basePath === activeReport?.basePath,
                                    'fw-bold':
                                        basePath === activeReport?.basePath,
                                })}
                                to={getPath(basePath)}
                            >
                                {label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ReportSwitcher;
