import { useDebugValue, useEffect, useState } from 'react';
import getMetadataJson from '../helpers/getMetadataJson';
import { getOneTimeUseGeoJsonAccessUrl, retrieveGeoJsonWithETag } from '../helpers/getPoiGeoJsonData';
import * as DB from '../helpers/db';
import { MetadataConfig } from '../types';
import { FeatureCollection as GeoJsonFeatureCollection } from '@turf/helpers';

const useGetGeoJsonData = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [metadata, setMetadata] = useState<MetadataConfig | null>(null);
    const [data, setData] = useState<GeoJsonFeatureCollection | null>(null);

    useDebugValue(
        `Number of points in GeoJSON: ${
            data == null ? 'GeoJSON not loaded!' : data.features.length
        }`,
    );

    useEffect(() => {
        let isSubscribed = true;
        // on route location change set default values
        setIsLoading(true);
        setError(null);

        const fetchMetadataAndData = async () => {
            try {
                const metadata: MetadataConfig = await getMetadataJson();

                // Guards
                if (!metadata.filterProperties || !metadata.filterProperties.length) {
                    console.warn('Missing filters in points metadata');
                    return;
                }
                if (!metadata.searchProperties || !metadata.searchProperties.length) {
                    console.warn('Missing search properties in points metadata');
                    return;
                }
                setMetadata(metadata);
                const collectionName = 'pointsBlob';
                const { collectionKey } = metadata.data.db;

                const signedUrl = await getOneTimeUseGeoJsonAccessUrl(metadata);

                const storedETag = await DB.get(collectionName, `${collectionKey}-etag`);

                let geoJson: string | null = null;
                if (signedUrl) {
                    const response = await retrieveGeoJsonWithETag(metadata, signedUrl, storedETag);
                    if (response.geojson) {
                        // If new GeoJSON has been retrieved, wipe the entire IndexedDB (to destroy other cached data, eg from .NET)
                        // That will force all other data to be refreshed once a new GeoJSON is available
                        // After that, set new geoJSON and etag, and move on
                        DB.deleteAllCollections();
                        geoJson = response.geojson;
                        await DB.set(collectionName, collectionKey, geoJson);
                        if (response.etag) {
                            await DB.set(collectionName, `${collectionKey}-etag`, response.etag);
                        }
                    } else {
                        geoJson = await DB.get(collectionName, collectionKey);
                    }
                }

                setData(JSON.parse(geoJson || ''));
                setIsLoading(false);
            } catch (e: any) {
                if (!isSubscribed) return;
                if (typeof e.message === 'string') {
                    setError(e.message);
                } else {
                    setError('Unknown error happened');
                }
                setIsLoading(false);
                return;
            }
        };
        fetchMetadataAndData();
        return () => {
            isSubscribed = false;
        };
    }, []);

    return { isLoading, error, data, metadata };
};

export default useGetGeoJsonData;
