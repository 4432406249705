import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { REPORTS } from './enums/Reports';
import useQueryParams from './hooks/useQueryParams';
import useCurrentUser from './hooks/useCurrentUser';
import { hasValidSubscription } from './helpers/subscription';
import { Context, getContext } from './helpers/context';

import AccessRequiredPage from './pages/selfstorage-prices/AccessRequiredPage';
import Loader from './components/Loader';
import ReportPage from './pages/ReportPage';
import useGetGeoJsonData from './hooks/useGetGeoJsonData';
import MarketSummaryReport from './pages/market-summary/MarketSummaryReport';

/**
 * This component will be always rendered
 * It's used to validate query params, so we don't have to check the query param error in each file
 * Q: Why is this not done in the App.tsx component?
 * A: You can't use the "useLocation" hook in the same component that puts the Router into the DOM
 *
 */
const Intersection = () => {
    const { error: queryParamsError } = useQueryParams();
    const { isLoading, error: userError, user } = useCurrentUser();
    const link: HTMLAnchorElement | null = document.querySelector(
        "link[rel~='icon']",
    );

    const {
        isLoading: isLoading2,
        error,
        metadata,
        data: geoJsonData,
    } = useGetGeoJsonData();

    useEffect(() => {
        document.title = 'Selfstorage Location Insights';
        if (link) {
            link.href = `${process.env.PUBLIC_URL}/favicon-selfstorage.ico`;
        }
    }, [link]);

    const context = getContext();

    // case when user is not logged in, or we cant fetch him, or we cant fetch
    // his subscriptions - generic error page goes here
    if (userError) {
        if (userError.message === 'Problem fetching current user') {
            // redirect user to login
            window.location.replace(context.loginUrl);
            return null;
        }
        return <div>Unable to fetch user licenses error page</div>;
    }

    if (isLoading || !user || isLoading2 || !metadata) {
        return <Loader />;
    }
    if (error) {
        return <div>{error}</div>;
    }

    if (queryParamsError) {
        // Block the rest of the page loading when there is any error in the required query params
        return <div>{queryParamsError}</div>;
    }

    // Check user subscription
    if (!hasValidSubscription(user)) {
        return (
            <Context.Provider value={context}>
                <AccessRequiredPage user={user} />
            </Context.Provider>
        );
    }

    const SelfStoragePriceReport = lazy(() =>
        import('./pages/selfstorage-prices/SelfStoragePriceReport'),
    );

    const SelfStorageRentalCompsReport = lazy(() =>
        import('./pages/selfstorage-comps/SelfStorageRentalCompsReport'),
    );

    const SelfStoragePointInfo = lazy(() =>
        import('./pages/selfstorage-point-info/SelfStoragePointInfo'),
    );

    const SelfstorageLocationProfileReport = lazy(() =>
        import('./pages/location-profile/SelfstorageLocationProfileReport'),
    );

    const OpportunityReport = lazy(() =>
        import('./pages/opportunity/OpportunityReport'),
    );
    const PageNotFound = lazy(() => import('./components/PageNotFound'));

    return (
        <Context.Provider value={context}>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route
                        path={REPORTS.pricing.basePath}
                        component={() => (
                            <ReportPage user={user}>
                                <SelfStoragePriceReport />
                            </ReportPage>
                        )}
                    />

                    <Route
                        path={REPORTS.rentalComps.basePath}
                        component={() => (
                            <ReportPage user={user}>
                                <SelfStorageRentalCompsReport />
                            </ReportPage>
                        )}
                    />
                    <Route
                        path={REPORTS.demography.basePath}
                        component={() => (
                            <ReportPage user={user}>
                                <SelfstorageLocationProfileReport />
                            </ReportPage>
                        )}
                    />
                    <Route
                        path={REPORTS.opportunity.basePath}
                        component={() => (
                            <ReportPage user={user}>
                                <OpportunityReport />
                            </ReportPage>
                        )}
                    />
                    <Route
                        path={REPORTS.selfStoragePointInfo.basePath}
                        component={() => (
                            <ReportPage user={user}>
                                <SelfStoragePointInfo
                                    geojson={
                                        geoJsonData as GeoJSON.FeatureCollection<
                                            GeoJSON.Geometry
                                        >
                                    }
                                />
                            </ReportPage>
                        )}
                    />
                    <Route
                        path={REPORTS.marketSummary.basePath}
                        component={() => (
                            <ReportPage user={user}>
                                <MarketSummaryReport
                                    geojson={
                                        geoJsonData as GeoJSON.FeatureCollection<
                                            GeoJSON.Geometry
                                        >
                                    }
                                    metadata={metadata}
                                />
                            </ReportPage>
                        )}
                    />
                    <Route>
                        <PageNotFound />
                    </Route>
                </Switch>
            </Suspense>
        </Context.Provider>
    );
};

export default Intersection;
