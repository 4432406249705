import { LayerLibrary } from '../types';

/**
 * Used to cache library.json response
 */
let libraryJson: LayerLibrary.Item[];

/**
 * Function that fetches library.json.
 *
 * File is retrieved from the network only once, and then cached. The following
 * calls will get cached version.
 *
 * @returns Promise that will be resolved as a content of library.json
 */
const getLibraryJson = async () => {
    if (!libraryJson) {
        const response = await fetch(
            `${process.env.REACT_APP_URL_DINGO_JSON_FILES}/library.json`,
        );
        libraryJson = await response.json();
    }
    return libraryJson;
};

export default getLibraryJson;
