import { Profile } from '../types';

export const AnalysisProfile = {
    radius: {
        unit: 'mile',
        unitLong: 'mile',
        unitLongPlural: 'miles',
        dingoProfile: 'radius',
        icon: 'album',
        profileVerbose: 'Radius',
    },
    driving: {
        unit: 'Min',
        unitLong: 'minute',
        unitLongPlural: 'minutes',
        dingoProfile: 'drive',
        icon: 'drive_eta',
        profileVerbose: 'Drive Time',
    },
    cycling: {
        unit: 'Min',
        unitLong: 'minute',
        unitLongPlural: 'minutes',
        dingoProfile: 'cycle',
        icon: 'directions_bike',
        profileVerbose: 'Cycle Time',
    },
    walking: {
        unit: 'Min',
        unitLong: 'minute',
        unitLongPlural: 'minutes',
        dingoProfile: 'walk',
        icon: 'directions_walk',
        profileVerbose: 'Walk Time',
    },
};

/**
 * Returns profile unit (singular or plural) depending on the selection
 */
export const getProfileUnit = (
    selection: number[],
    profile: Profile,
    useShortUnit: boolean = false,
) => {
    if (useShortUnit) {
        return AnalysisProfile[profile].unit;
    }

    return selection.length > 1 || selection[0] > 1
        ? AnalysisProfile[profile].unitLongPlural
        : AnalysisProfile[profile].unitLong;
};
