import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import getPoints from '../helpers/getPoints';
import getLibraryJson from '../../../helpers/getLibraryJson';
import getQueryParams from '../../../helpers/getQueryParams';
import * as DB from '../../../helpers/db';
import { getKeyFromPointProfileSelection } from '../../../helpers/Util';

import {
    LayerLibrary,
    MapQueriedFacilitiesByUdaBySurvey,
    MapQueriedPointsBySurveyByUda,
    Point,
    Profile,
    MetadataConfig,
} from '../../../types';
import getGeoJsonPoints from '../helpers/getGeojsonPoints';

const usePoints = (
    geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
    metadata: MetadataConfig,
) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [
        pointsBySurveyByUda,
        setPointsBySurveyByUda,
    ] = useState<MapQueriedPointsBySurveyByUda | null>(null);
    const location = useLocation().search;

    useEffect(() => {
        let isSubscribed = true;
        // on route location change set default values
        setIsLoading(true);
        const fetchJsonAndFipsCodes = async () => {
            let selection: number[];
            let point: Point;
            let profile: Profile;
            let library: LayerLibrary.Item[];
            try {
                ({ point, profile, selection } = getQueryParams(location));

                let mapQueriedPointsBySurveyByUda: MapQueriedFacilitiesByUdaBySurvey | null;
                let mapQueriedGeoJsonPointsBySurveyByUda: MapQueriedFacilitiesByUdaBySurvey | null;
                const key = getKeyFromPointProfileSelection(
                    point,
                    profile,
                    selection,
                );
                mapQueriedPointsBySurveyByUda = await DB.get('opoints', key);

                if (!mapQueriedPointsBySurveyByUda) {
                    library = await getLibraryJson();
                    mapQueriedPointsBySurveyByUda = await getPoints(
                        point,
                        profile,
                        selection,
                        library,
                    );

                    mapQueriedGeoJsonPointsBySurveyByUda = await getGeoJsonPoints(
                        point,
                        profile,
                        selection,
                        geojson,
                        metadata,
                    );
                    mapQueriedPointsBySurveyByUda = {
                        ...mapQueriedGeoJsonPointsBySurveyByUda,
                        ...mapQueriedPointsBySurveyByUda,
                    };
                    DB.set('opoints', key, mapQueriedPointsBySurveyByUda);
                }
                if (isSubscribed) {
                    setPointsBySurveyByUda(mapQueriedPointsBySurveyByUda);
                    setIsLoading(false);
                }
            } catch (e) {
                if (!isSubscribed) return;
                if (e instanceof Error && typeof e.message === 'string') {
                    setError(e.message);
                } else {
                    setError('Unknown error happened');
                }
                setIsLoading(false);
                return;
            }
        };
        fetchJsonAndFipsCodes();
        return () => {
            isSubscribed = false;
        };
    }, [location, geojson, metadata]);

    return { isLoading, error, pointsBySurveyByUda };
};

export default usePoints;
