import { useParams } from 'react-router';

const useRouteParams: () => {
    error: string | null;
    frame: string | undefined;
} = () => {
    const params: { frame?: string } = useParams();

    return {
        frame: params.frame === 'frame' ? params.frame : undefined,
        error: null,
    }
};

export default useRouteParams;
