import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { getProfileUnit } from '../enums/AnalysisProfile';
import useQueryParams from '../hooks/useQueryParams';

const BREAKING_CLIENT_WIDTH = 768;

type Props = {
    onClick: (selection: number | null) => void;
};

const SelectionSwitcher = ({ onClick }: Props) => {
    const { profile, selection } = useQueryParams();
    const [selectedSelection, setSelectedSelection] = useState<number | null>(
        null,
    );

    useEffect(() => {
        const handleSelection = () => {
            if (
                document.body.clientWidth < BREAKING_CLIENT_WIDTH &&
                selectedSelection == null
            ) {
                setSelectedSelection(selection[0]);
                // notify the parent about the selection change
                onClick(selection[0]);
            } else if (
                document.body.clientWidth >= BREAKING_CLIENT_WIDTH &&
                selectedSelection !== null
            ) {
                setSelectedSelection(null);
                onClick(null);
            }
        };
        handleSelection();
        window.addEventListener('resize', handleSelection);
        return () => {
            window.removeEventListener('resize', handleSelection);
        };
    }, [selection, selectedSelection, onClick]);

    return (
        <div
            className={classNames(
                'row border-bottom py-16 d-md-none ms-0 me-24 selection-switcher',
                {
                    'd-none': selection.length <= 1,
                },
            )}
        >
            <ul className="nav nav-tabs d-flex justify-content-end col-12 ">
                {selection.map((s) => {
                    return (
                        <li className="nav-item" key={s}>
                            <button
                                type="button"
                                className={classNames(
                                    'nav-link',
                                    'mx-0',
                                    'text-capitalize',
                                    'fw-bold',
                                    'opacity-074',
                                    'rounded-0',
                                    {
                                        'active text-tractiq fw-bold':
                                            selectedSelection === s
                                    },
                                )}
                                onClick={() => {
                                    setSelectedSelection(s);
                                    onClick(s);
                                }}
                            >
                                <div>{s} {getProfileUnit([s], profile)}</div>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SelectionSwitcher;
