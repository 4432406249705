import { DataVisualizationsDescription } from '../types';

const DEFAULT_TITLE = 'Source: ';
const DEFAULT_DESCRIPTION = 'Easy Analytic Software, Inc. (EASI &reg;).';

/**
 * @remark Descriptions which are longer are divided into multiple smaller strings,
 *         and then concatinated using the plus (+) operator. This is done to prevent
 *         jsPDF registering the press of the ENTER key as a new line, and thus breaking
 *         text where it should not be broken.
 */
const CHART_DATA_INFO: DataVisualizationsDescription = {
    // used for demography report
    population: {
        title: 'Population',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    historicalTrends: {
        title: 'Historical Trends in Population',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report and opportunity report
    populationProjections: {
        title: 'Population Growth Projections',
        items: [
            {
                title: 'Housing Starts: ',
                description:
                    `Our own population projection indicator derived from housing and construction data. ` +
                    `Our database tracks the location, number of units and the status for about 37,000 housing and ` +
                    `construction projects. Our housing starts population projection is calculated by summation of ` +
                    `the current population (available from Easy Analytic Software, Inc. (EASI &reg;)), and the ` +
                    `number of housing units available in a user-defined area multiplied by the estimate of the ` +
                    `average size of a household unit.`,
            },
        ],
    },
    // used for demography report
    households: {
        title: 'Number of Households',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    houseHoldIncomeMedian: {
        title: 'Household Income (Median)',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    houseHoldIncomeAverage: {
        title: 'Household Income (Average)',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    householdsByIncome: {
        title: 'Households by Income',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    travelTimeToWork: {
        title: 'Travel Time to Work',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    housingVacancy: {
        title: 'Vacancy (Current and Projected)',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    housingTenure: {
        title: 'Tenure (Current and Projected)',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for demography report
    medianRent: {
        title: 'Median Rent',
        items: [
            {
                title: DEFAULT_TITLE,
                description: DEFAULT_DESCRIPTION,
            },
        ],
    },
    // used for opportunuty report
    squareFootagePerCapita: {
        title: 'Square Footage Per Capita (Current and Projected)',
        items: [
            {
                title: 'Current: ',
                description:
                    `Shows the ratio between the current rentable square feet (75% of the gross square footage) ` +
                    `of currently operating facilities and the current population.`,
            },
            {
                title: 'Census Projection: ',
                description:
                    `Shows the ratio between the projected rentable square feet (75% of the gross square footage) ` +
                    `of currently operating facilities and those in construction, and the census-projected population.`,
            },
            {
                title: 'Housing Starts: ',
                description:
                    `Shows the ratio between the projected rentable square feet (75% of the gross square footage) of ` +
                    `currently operating facilities and those in construction and our own housing-starts-based population projection.`,
            },
        ],
    },
    // used for opportunity report
    squareFootageOfSS: {
        title: 'Square Footage of SS (Gross and Rentable)',
        items: [
            {
                title: 'Gross: ',
                description:
                    `Shows the gross square footage of the facilities in the user-defined area.` +
                    `Gross square footage for each facility is obtained by calculating the footprint of ` +
                    `the building(s) and multiplying it by the number of floors.`,
            },
            {
                title: 'Rentable: ',
                description: `Shows the 75% of the gross square footage of facilities in the user-defined area.`,
            },
        ],
    },
    // used for opportunity report
    squareFootageOfSSProjections: {
        title: 'Square Footage Projection of SS (Gross and Rentable)',
        items: [
            {
                title: 'Gross: ',
                description:
                    `Shows the projected square footage for self storage facilities in the user-defined area. ` +
                    `The projected square footage is equal to the sum of the current gross square footage ` +
                    `and the square footage of incoming facilities.`,
            },
            {
                title: 'Rentable: ',
                description: `Shows the 75% of the gross square footage of facilities and construction sites in the user-defined area.`,
            },
        ],
    },
    // used for pricing report
    GENERAL: {
        title: 'General Information',
        items: [
            {
                title: 'About this Data: ',
                description:
                    `Data in all charts includes walk-in pricing rates published online for ` +
                    `primary storage units, excluding specialty storage such as vehicle storage ` +
                    `and lockers. Data is collected on a weekly basis and aggregated onto a month.`,
            },
        ],
    },
    // used for pricing report
    UNIT_SIZE: {
        title: 'Unit Sizes',
        items: [
            {
                title: 'Small Units ',
                description:
                    `(<100 sq. ft): Includes standard sizes such as 5x5, 5x10, as well as ` +
                    `less standard sizes (e.g., 5x7).`,
            },
            {
                title: 'Medium Units ',
                description:
                    `(100 - 200 sq. ft): Includes standard sizes such as 10x10, 10x15, 10x20, ` +
                    `as well as less standard sizes (e.g. 10x18).`,
            },
            {
                title: 'Large Units ',
                description:
                    `(>200 sq. ft): Includes standard sizes such as 10x25, 10x30, as well as ` +
                    `less standard sizes (e.g. 12x30).`,
            },
        ],
    },
    // used for pricing report
    CC: {
        title: 'Climate Controlled',
        items: [
            {
                title: 'Climate Controlled: ',
                description:
                    `Includes data points for the climate controlled, humidity controlled, ` +
                    `and heat controlled units.`,
            },
            {
                title: 'Non-Climate Controlled: ',
                description:
                    `Includes data points for traditional units not ` +
                    `featuring any type of climate control.`,
            },
        ],
    },
    // used for pricing report
    ACCESS: {
        title: 'Type of Access',
        items: [
            {
                title: 'Ground Floor: ',
                description:
                    `Includes all inside units immediately accessible on ` +
                    `the ground floor. Does not include the drive-up units.`,
            },
            {
                title: 'Elevator: ',
                description:
                    `Includes all units not on the ground floor but ` +
                    `accessible via elevator (e.g., 2<sup>nd</sup> floor, basement).`,
            },
        ],
    },
    // used for pricing report
    DRIVE: {
        title: 'Drive-up',
        items: [
            {
                title: 'Drive-up: ',
                description: `Includes all units with drive-up access`,
            },
        ],
    },

    retailSales: {
        title: 'Retail Sales',
        items: [
            {
                title: 'Retail Sales: ',
                description: `Retail sales measures the total retail sales on the block group level. This metric is 
                on the block group level and is calculated both for the current and 5-year out projections. Source: EASI Data.`,
            },
        ],
    },

    // used for SS market summary
    projectionsInfo: {
        title: 'Projections (incoming supply & demand)',
        items: [
            {
                title: 'About this data:',
                description: `Both projections model 5-year projected population.`,
            },
            {
                title: 'Census-based population projections:',
                description: `Modeled by EASI Demographics.`,
            },
            {
                title: 'Housing-based population growth:',
                description: `Modeled by TractIQ by analyzing the number of incoming housing projects and 
                the likelihood of delivery and stabilization within 5 years as well as house size and occupancy rate in the trade area.
                This calculation excludes any “stale” housing projects that lack updates within the last 18 months.`,
            },
        ],
    },

    // used for SS market summary
    avgPrices: {
        title: 'Average Market Rate per Square Foot',
        items: [
            {
                title: 'About this data:',
                description: `Average Market Rate per Square Foot is the average of the latest 12 months average rate in the market for all available units of the indicated category (e.g. 5x5 Climate Controlled). `,
            },
        ],
    },
};

export default CHART_DATA_INFO;
