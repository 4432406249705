import { useState } from 'react';
import Loader from '../../components/Loader';
import ReportSwitcherAndLocationSection from '../../components/ReportSwitcherAndLocationSection';
import SelectionSwitcher from '../../components/SelectionSwitcher';
import DiveDeeperSection from './DiveDeeperSection';
import { REPORT_SECTIONS } from './helpers/getReportDefitintion';
import { FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import { MetadataConfig, SiteAnalysisExecutiveSummary } from '../../types';
import useOlapSiteAnalysis from '../../hooks/useOlapSiteAnalysis';
import Sections from '../../components/Sections';
import PdfExport from './components/PdfExport';
import { SIZES } from '../selfstorage-comps/constants/compReportConstants';

type Props = {
    geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    metadata: MetadataConfig;
};

const MarketSummaryReport = ({ geojson, metadata }: Props) => {
    const [selectedSelection, setSelectedSelection] = useState<number | null>(
        null,
    );

    const { isLoading, error, data } = useOlapSiteAnalysis({
        q: 'executive-summary',
    });

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!data || !metadata || !geojson) {
        return null; // TODO: Figure out the message
    }

    const calculatePercentageOf = (part: number, total: number) => {
        if (total === 0) {
            return 0; // To avoid division by zero
        }
        return ((part ?? 0) / total) * 100;
    };

    data.forEach((selection) => {
        const olapData = selection.data as SiteAnalysisExecutiveSummary;

        olapData.rent_sqft_driveup_percentage = calculatePercentageOf(
            olapData.rent_sqft_driveup,
            olapData.rent_sqft,
        );
        olapData.facility_operating_has_cc_percentage = calculatePercentageOf(
            olapData.facility_operating_has_cc,
            olapData.facility_operating,
        );
        olapData.renters_percentage = calculatePercentageOf(
            olapData.renters,
            olapData.households,
        );
        olapData.homeowners_percentage = calculatePercentageOf(
            olapData.homeowners,
            olapData.households,
        );
        olapData.population_projected_housing_percentage = calculatePercentageOf(
            olapData.population_projected_housing - olapData.population,
            olapData.population,
        );
        olapData.population_projected_census_percentage = calculatePercentageOf(
            olapData.population_projected_census - olapData.population,
            olapData.population,
        );

        olapData.avg_market_rates = SIZES.map((cat) => {
            const avgRates = olapData.latest_available_avg_street_rates || [];
            const findRate = (climateControl: boolean) =>
                avgRates.find(
                    (rate) =>
                        rate.size_category === cat &&
                        rate.climate_control === climateControl,
                )?.average_street_rate_per_sqft;

            return {
                unitSize: cat,
                amb: findRate(false),
                cc: findRate(true),
            };
        });
    });

    return (
        <div className="container bg-white shadow-sm rounded-top px-0">
            <ReportSwitcherAndLocationSection>
                <PdfExport
                    summaryData={data}
                    geojson={
                        geojson as FeatureCollection<Point, GeoJsonProperties>
                    }
                    metadata={metadata}
                />
            </ReportSwitcherAndLocationSection>
            <h4 className="mt-12 text-start mx-20">Executive Summary</h4>
            <SelectionSwitcher onClick={setSelectedSelection} />
            <Sections
                selectedSelection={selectedSelection}
                reportSections={REPORT_SECTIONS}
                data={data}
            />
            <DiveDeeperSection />
        </div>
    );
};

export default MarketSummaryReport;
