import {
    Point,
    Profile,
    SelectionFeatures,
    LayerLibrary,
    JsonResponse,
    DataSource,
} from '../types';
import getMapJson from './getMapJson';
import { IsochronesResponse } from 'mapbox-gl';


export const getSelectionFeatures = async (
    point: Point,
    profile: Profile,
    selection: number[],
): Promise<SelectionFeatures[]> => {
    const contours = selection.join(',');
    const url = `${process.env.REACT_APP_URL_BACKEND_RAILS}/contours?lng=${point.lng}&lat=${point.lat}&type=${profile}&values=${contours}&polygons=true`;
    const selectionResponses = await fetch(url);
    const selectionData = await selectionResponses.json();
    const selectionFeatures = selectionData.features.map(
        (f: IsochronesResponse) => {
            return {
                selection: f.properties.contour,
                features: {
                    ...f,
                    properties: {
                        ...f.properties,
                        // add additional properties
                        name: profile === 'radius' ? 'radius-selection' : 'isochrone-selection',
                        value: f.properties.contour,
                        point,
                    },
                },
            };
        },
    );

    return selectionFeatures;
};

export const fetchDataSourceBySurveyCode = async (
    surveyCode: string,
    library: LayerLibrary.Item[],
    summaryLevel?: string,
) => {
    const libraryGroup = library.find(
        (group) =>
            group.metadata.report &&
            group.metadata.report.surveyCode === surveyCode &&
            group.metadata.report.dataSourceLayer,
    );
    if (libraryGroup) {
        const dsJSON = libraryGroup.metadata.report.dataSourceLayer;
        const dataSource = parseDataSource(dsJSON, surveyCode);
        return dataSource;
    }
    // If nothing is found in the library json, let's fetch the survey json file
    const mapJson = await getMapJson(surveyCode);
    const dataSourceLayers = mapJson.metadata.socialexplorer[
        'data-source-layers'
    ].map((dataSourceJSON: JsonResponse.DataSource) =>
        parseDataSource(dataSourceJSON, surveyCode),
    );

    const reportSummaryLevel =
        summaryLevel ||
        mapJson.metadata.socialexplorer['report-min-summary-level'];
    return dataSourceLayers.find(
        (ds: DataSource) => ds.summaryLevel.id === reportSummaryLevel,
    );
};

const parseDataSource = (
    dataSourceJSON: JsonResponse.DataSource,
    surveyCode: string,
) => {
    const dataSource: DataSource = {
        id: dataSourceJSON.id,
        layerId: dataSourceJSON['geo-layer-id'],
        summaryLevel: {
            ...dataSourceJSON['summary-level'],
            visible: true,
        },
        datasets: {},
    };
    dataSourceJSON.datasets.forEach((datasetJSON) => {
        const id = datasetJSON['dataset-id'];
        dataSource.datasets[id] = {
            id,
            datasetAbbreviation: datasetJSON['dataset-abbreviation'],
            primaryKeyField: datasetJSON['primary-key-column'],
            geoNameField: datasetJSON['geo-name-column'],
            geoQNameField: datasetJSON['geo-qualified-name-column'],
            geoTypeName: dataSource.summaryLevel.id,
            surveyName: datasetJSON['survey-name'] || surveyCode,
        };
    });
    return dataSource;
};
