import {
    MapQueriedFacilitiesByUdaBySurvey,
    SiteAnalysis,
    SelfStorage,
} from '../types';

const TIME_3_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
const TIME_5_HOURS_IN_MS = 5 * 60 * 60 * 1000;

type DB_COLLECTIONS = {
    sspointinfo: SelfStorage.PointInfo;
    opoints: MapQueriedFacilitiesByUdaBySurvey;
    here: string;
    pointsBlob: string;
    olapData: SiteAnalysis;
};

const DB_COLLECTIONS_VALIDITY: {
    [key in keyof DB_COLLECTIONS]: number | undefined;
} = {
    sspointinfo: TIME_3_DAYS_IN_MS,
    opoints: TIME_3_DAYS_IN_MS,
    here: TIME_5_HOURS_IN_MS,
    pointsBlob: undefined,
    olapData: TIME_3_DAYS_IN_MS,
};

const getObjectStore = async (
    collection: string,
    version: number = 1,
): Promise<IDBObjectStore> =>
    new Promise((resolve, reject) => {
        if (!window.indexedDB) {
            reject('indexDB not supported');
            return;
        }
        const request = indexedDB.open(collection, version);
        request.onerror = (e) => {
            // this can happen if the calculated version number is lower then the one stored
            // in browser. Steps to reproduce this is:
            //  - Set cache time to 3 days
            //  - deploy and let users use it
            //  - set cache time to 7 days
            //  - deploy
            //
            // In that case we want to delete database
            const request = indexedDB.deleteDatabase(collection);
            request.onsuccess = () => {};
            request.onerror = () => {};
            reject('Error opening IndexedDB');
        };
        request.onupgradeneeded = (event) => {
            const target = event.target as IDBOpenDBRequest | null;
            if (!target) {
                reject('There was no target db on upgrade');
                return;
            }

            const db = target.result;
            try {
                db.deleteObjectStore(collection);
            } catch (e) {
                // this should only happen if this is the first time database
                // is created. I couldn't find a method to check if objectStore
                // already exists without throwing exception
            }
            db.createObjectStore(collection, { keyPath: 'id' });
        };
        request.onsuccess = (event) => {
            const target = event.target as IDBOpenDBRequest | null;
            if (!target) {
                reject('There was no target db on success');
                return;
            }

            const db = target.result;
            resolve(
                db.transaction(collection, 'readwrite').objectStore(collection),
            );
        };
    });

/**
 * Clears entire indexedDB (all collections)
 */
export const deleteAllCollections = () => {
    const collections = Object.keys(DB_COLLECTIONS_VALIDITY);
    collections.forEach((collection) => {
        const request = indexedDB.deleteDatabase(collection);
        request.onsuccess = () => {};
        request.onerror = () => {
            console.error(
                `Error deleting database ${collection}! Stale data might be displayed!`,
            );
        };
    });
};

export const rawGet = async (
    collection: string,
    key: string,
    version: number = 1,
): Promise<any> => {
    try {
        const objectStore = await getObjectStore(collection, version);
        const value = await new Promise((resolve) => {
            const request = objectStore.get(key);
            request.onerror = () => resolve(null);
            request.onsuccess = (event) => {
                const target = event.target as IDBRequest | undefined;
                if (!target) {
                    resolve(null);
                    return;
                }
                const result = target.result as DbStruct | undefined;
                if (!result) {
                    resolve(null);
                    return;
                }
                resolve(result.payload);
            };
        });
        return value;
    } catch (e) {
        return null;
    }
};

export const rawSet = async (
    collection: string,
    key: string,
    version: number = 1,
    payload: any,
): Promise<void> => {
    try {
        const objectStore = await getObjectStore(collection, version);
        await new Promise<void>((resolve) => {
            const request = objectStore.put({
                id: key,
                payload,
            });
            request.onsuccess = () => resolve();
            request.onerror = () => {
                console.warn(`Failed to set ${key} in ${collection}!`);
            };
        });
    } catch (e) {
        console.error(`Failed to set ${key} in ${collection}: ${e}`);
        return;
    }
};

const getCollectionVersion = (collection: keyof DB_COLLECTIONS): number => {
    const timeInCache = DB_COLLECTIONS_VALIDITY[collection];
    if (timeInCache) {
        return Math.round(new Date().getTime() / timeInCache);
    }
    return 1;
};

export const get = async <T extends keyof DB_COLLECTIONS>(
    collection: T,
    key: string,
): Promise<DB_COLLECTIONS[T] | null> => {
    return (await rawGet(
        collection,
        key,
        getCollectionVersion(collection),
    )) as Promise<DB_COLLECTIONS[T] | null>;
};

export async function set<T extends keyof DB_COLLECTIONS>(
    collection: T,
    key: string,
    payload: DB_COLLECTIONS[T],
): Promise<void> {
    return await rawSet(
        collection,
        key,
        getCollectionVersion(collection),
        payload,
    );
}

/**
 * Internal structure used to store data in indexDB
 */
interface DbStruct {
    id: number;
    payload: any;
}
