import classNames from 'classnames';
import { ReactElement } from 'react';
import {
    SectionVisualizationLabel,
    LabelSeries,
    SiteAnalysisBase,
} from '../types';
import { formatValue } from '../helpers/ValueFormatter';

type Props = {
    data: SiteAnalysisBase;
    visualization: SectionVisualizationLabel;
    showHeader: boolean;
};

const LabelVisualization = ({ data, visualization, showHeader }: Props) => {
    if (!visualization.labels) {
        return null;
    }
    const labels: ReactElement[] = [];

    const numberOfLabels = visualization.labels?.length || 0;
    visualization.labels.forEach((label: LabelSeries, index) => {
        if (!data) {
            return <div>Data is empty</div>;
        }
        const variable = data[label.variable];
        if (variable) {
            const classes = classNames(
                'd-flex',
                'flex-column',
                'flex-grow-1',
                'ms-16',
                'ms-md-32',
                {
                    'me-32':
                        index + 1 === numberOfLabels ||
                        !visualization.showLabelsInOneLine,
                    'mt-12':
                        !visualization.showLabelsInOneLine &&
                        numberOfLabels > 1 &&
                        index > 0,
                },
            );

            labels.push(
                <div key={label.variable} className={classes}>
                    <h6 className="me-8 text-start opacity-062">
                        {label.title}
                    </h6>
                    <h2 className="text-start">
                        {formatValue(variable, visualization.valueFormat)}
                    </h2>
                </div>,
            );
        }
    });

    if (!labels.length) return null;

    const labelsWrapperClasses = classNames('d-flex', {
        'flex-row': visualization.showLabelsInOneLine,
        'flex-column': !visualization.showLabelsInOneLine,
        'border-start': !showHeader,
    });

    return (
        <div className="border-bottom py-24">
            <h4
                className={classNames('text-start', 'px-16', 'px-md-32', {
                    'pb-24': visualization.header,
                    invisible: !showHeader,
                })}
            >
                {visualization.header}
            </h4>
            <div className={labelsWrapperClasses}>{labels}</div>
        </div>
    );
};

export default LabelVisualization;
