import DownloadOptionsDropdown from '../pages/DownloadOptionsDropdown';

import { ReportType } from '../types';
import Spinner from './loaders/Spinner';

type Props = {
    shouldDisableButton: boolean;
    isDownloading: boolean;
    downloadOptions: boolean;
    onClickReport: Function;
    setDownloadOptions: Function;
    setDownloadPdfWithPagination: Function;
    downloadPdfWithPagination: boolean;
    menuClass?: string;
    reportTypes?: ReportType;
    setShouldShow?: Function;
};

const PdfExportButton = ({
    shouldDisableButton,
    isDownloading,
    setShouldShow,
    onClickReport,
    setDownloadOptions,
    downloadOptions,
    downloadPdfWithPagination,
    setDownloadPdfWithPagination,
    menuClass,
    reportTypes,
}: Props) => {
    const style = { width: 'fit-content' };
    return (
        <div className="border-start my-16 mx-lg-16 mx-md-32 me-4">
            <div className="row d-flex border rounded-pill px-8 ms-12 shadow--standard align-items-center me-0">
                <button
                    className="btn col-auto fw-bold align-middle px-8"
                    type="button"
                    data-bs-toggle={reportTypes ? 'dropdown' : null}
                    aria-expanded={reportTypes ? false : true}
                    data-bs-offset={reportTypes ? '0,4' : null}
                    disabled={shouldDisableButton || isDownloading}
                    onClick={
                        reportTypes && setShouldShow
                            ? () => setShouldShow(true)
                            : () => onClickReport()
                    }
                >
                    <span className="d-inline d-lg-none">PDF</span>
                    <span className="d-none d-lg-inline">
                        {isDownloading ? 'Exporting...' : 'Download PDF'}
                    </span>
                    {isDownloading ? (
                        <Spinner />
                    ) : (
                        <i className="material-icons ms-4 text-tractiq align-text-bottom">
                            download
                        </i>
                    )}
                </button>
                {reportTypes ? (
                    <ul className={menuClass} role="menu" style={style}>
                        {Object.keys(reportTypes).map((key) => {
                            return (
                                <li key={key}>
                                    <button
                                        className="dropdown-item dropdown-item--standard px-20 py-8"
                                        onClick={() => onClickReport(key)}
                                    >
                                        {reportTypes[key]?.title}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
                <button
                    className="btn col-auto d-flex rounded-0 border-start justify-content-center px-0"
                    onClick={() => {
                        setDownloadOptions(!downloadOptions);
                    }}
                >
                    <i className="material-icons align-middle ps-8">
                        {downloadOptions
                            ? 'keyboard_arrow_up'
                            : 'keyboard_arrow_down'}
                    </i>
                </button>
                {downloadOptions && (
                    <DownloadOptionsDropdown
                        setDownloadOptions={setDownloadOptions}
                        downloadPdfWithPagination={downloadPdfWithPagination}
                        setDownloadPdfWithPagination={
                            setDownloadPdfWithPagination
                        }
                        dropdownClasses="download-options__retail_gap_report"
                    />
                )}
            </div>
        </div>
    );
};

export default PdfExportButton;
