import { useLocation } from 'react-router';
import getQueryParams from '../helpers/getQueryParams';
import { Point, Profile, SelfStorage } from '../types';

const useQueryParams: () => {
    point: Point;
    profile: Profile;
    selection: number[];
    pointType: SelfStorage.PointType | null;
    title: string | null;
    industry: string | null;
    pois: string[];
    error: string | null;
    facilityId: string | null;
} = () => {
    const location = useLocation();
    try {
        const queryParams = getQueryParams(location.search);
        return {
            ...queryParams,
            error: null,
        };
    } catch (e) {
        return {
            point: { lat: 0, lng: 0 },
            profile: 'radius',
            selection: [],
            pointType: null,
            title: null,
            industry: null,
            pois: [],
            error: (e as any).message,
            facilityId: null,
        };
    }
};

export default useQueryParams;
