import { useContext } from 'react';
import { Context } from '../helpers/context';
import { ReactComponent as TractIQLogo } from '../assets/tractiqLogo.svg';

const DashboardLogoLink = () => {
    const { dashboardUrl } = useContext(Context);
    return (
        <a href={dashboardUrl}>
            <TractIQLogo />
        </a>
    );
};

export default DashboardLogoLink;
