import { User } from '../types';
import Header from '../components/Header';

type Props = {
    user: User;
    children: React.ReactNode
};

const ReportPage = ({ user, children }: Props) => {
    return (
        <div className="container-fluid px-0">
            <div className="d-flex flex-column flex-grow-1">
                <Header user={user} />
                {children}
            </div>
        </div>
    );
};

export default ReportPage;
