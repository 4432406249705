const HEIGHT = 24;
const WIDTH = 1;

const VerticalDivider = () => (
    <span
        className="mx-16 border-start"
        style={{ height: HEIGHT, width: WIDTH }}
    />
);

export default VerticalDivider;
