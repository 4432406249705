import classNames from 'classnames';
import { SiteAnalysisBase, SectionVisualizations } from '../types';

import useQueryParams from '../hooks/useQueryParams';
import { getColClassNumber } from '../helpers/Util';
import { getProfileUnit } from '../enums/AnalysisProfile';
import LabelVisualization from './LabelVisualizations';
import Chart from './Chart';
import TableSection from '../pages/market-summary/components/TableSection';
import GroupedTableSection from '../pages/market-summary/components/GroupedTableSection';
import ListTableSection from '../pages/market-summary/components/ListTableSection';

type Props = {
    selection: number;
    selectedSelection: number | null;
    visualizations: SectionVisualizations;
    data: SiteAnalysisBase;
};

const UdaSection = ({
    selection,
    selectedSelection,
    visualizations,
    data,
}: Props) => {
    const { selection: queryParamSelection, profile } = useQueryParams();
    const noOfSelections = queryParamSelection.length;
    const selected = selectedSelection && selection === selectedSelection;
    const colNumber = getColClassNumber(
        selectedSelection !== null,
        noOfSelections,
    );
    const exportTitleSuffix = `${selection} ${getProfileUnit(
        queryParamSelection,
        profile,
        false,
    )} ${profile}`;
    const classes = classNames(`col-${colNumber}`, 'px-0', {
        'd-none': selectedSelection && !selected,
    });
    const isFirstSelection = selectedSelection
        ? true // show for all
        : selection === queryParamSelection[0]; // show only for first selection

    const visualizationComponents = visualizations.map((visualization) => {
        const containerId = `${visualization.id}${selection}`;
        switch (visualization.type) {
            case 'label':
                return (
                    <LabelVisualization
                        key={containerId}
                        data={data}
                        visualization={visualization}
                        showHeader={isFirstSelection}
                    />
                );
            case 'chart':
                return (
                    <Chart
                        key={containerId}
                        containerId={containerId}
                        visualization={visualization}
                        data={data}
                        showTitle={isFirstSelection}
                        exportTitleSuffix={exportTitleSuffix}
                    />
                );
            case 'table':
                return (
                    <TableSection
                        key={containerId}
                        data={data}
                        visualization={visualization}
                    />
                );
            case 'groupedTable':
                return (
                    <GroupedTableSection
                        key={containerId}
                        data={data}
                        visualization={visualization}
                        showAboutThisData={isFirstSelection}
                    />
                );
            case 'listTable':
                return (
                    <ListTableSection
                        key={containerId}
                        data={data}
                        visualization={visualization}
                        showAboutThisData={isFirstSelection}
                    />
                );
            default:
                return <div>{visualization.title}</div>;
        }
    });

    return <div className={classes}>{visualizationComponents}</div>;
};

export default UdaSection;
