import mapboxgl, { LngLatLike, MapboxOptions } from 'mapbox-gl';
import {
    Boundary,
    BoundingBox,
    DatasetAbbreviationColumnName,
    DataSource,
    MapQueryResponse,
    MapSourceLayer,
    Point,
    SourceLayer,
    SourceLayerDataset,
} from '../types';
import { Feature, FeatureCollection } from '@turf/helpers';
import { pointsWithinPolygon, polygon } from '@turf/turf';
import { Point as TurfPoint } from '@turf/helpers/dist/js/lib/geojson';

const SOURCE_NAME = 'geobuffer';

export default class QueryFromMap {
    private _map: mapboxgl.Map | undefined;
    private _columns: { [id: string]: DatasetAbbreviationColumnName[] };
    private _sources: { [id: string]: DataSource };
    private _isPolygon: { [id: string]: boolean };
    private _geoJsonSources: mapboxgl.Sources;
    private _geoJsonLayers: mapboxgl.AnyLayer[];
    private _token: string;

    constructor() {
        this._map = undefined;
        this._sources = {};
        this._isPolygon = {};
        this._columns = {};
        this._geoJsonSources = {};
        this._geoJsonLayers = [];
        this._token = '';
    }

    _reset() {
        this._map = undefined;
        this._sources = {};
        this._isPolygon = {};
        this._columns = {};
        this._geoJsonSources = {};
        this._geoJsonLayers = [];
        this._token = '';
    }

    /**
     * Add source that will be used to query data from the map. Id will be used
     * as a reference to retrieve data and to set columns to query
     *
     * @param id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param source
     * @param isPolygon
     */
    addSource(id: string, source: DataSource, isPolygon: boolean): void {
        if (this._map) {
            throw new Error(
                'Adding sources after map is initialized is not allowed. You should destroy this map and create a new one',
            );
        }
        this._sources[id] = source;
        this._isPolygon[id] = isPolygon;
    }

    /**
     * Add columns that will be used to query map.
     *
     * @param id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param columns Name of the columns in geobuffer with dataset abbreviation prefix
     */
    addColumns(id: string, columns: DatasetAbbreviationColumnName[]) {
        if (this._map) {
            throw new Error(
                'Adding columns after map is initialized is not allowed. You should destroy this map and create a new one',
            );
        }
        this._columns[id] = columns;
    }

    /**
     * Get column names in geobuffer with dataset abbreviation of geo fips code
     * and geo name
     *
     * @param id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @returns First element is geoFips, second is geo name
     */
    getGeoColumnNames(
        id: string,
    ): [DatasetAbbreviationColumnName, DatasetAbbreviationColumnName] {
        const source = this._sources[id];
        const firstDataset = Object.values(source.datasets)[0];
        return [
            {
                datasetAbbreviation: firstDataset.datasetAbbreviation,
                columnName: firstDataset.primaryKeyField,
            },
            {
                datasetAbbreviation: firstDataset.datasetAbbreviation,
                columnName: firstDataset.geoNameField,
            },
        ];
    }

    setMapboxAccessToken(token: string) {
        this._token = token;
    }

    createMap(mode: string) {
        if (this._map) {
            throw new Error(
                'Map is already created. Please destroy this one and create a new map',
            );
        }
        return new Promise((resolve) => {
            // Create hidden map container
            const container = document.createElement('div');
            container.classList.add('map');
            document.body.appendChild(container);

            if (mode === 'geobuffer') {
                const sourceLayers = this._getSourceLayers();
                const presentationLayers = this._getPresentationLayers();

                const mapJSON: MapboxOptions = {
                    container,
                    center: [0, 0],
                    zoom: 20,
                    dragRotate: false,
                    style: {
                        version: 8,
                        sprite: `${process.env.REACT_APP_URL_ASSETS_BASE}/sprite/sprite`,
                        sources: {
                            [SOURCE_NAME]: {
                                type: 'vector',
                                tiles: [this._getTiles(sourceLayers)],
                            },
                        },
                        layers: presentationLayers,
                    },
                };
                mapboxgl.accessToken = this._token;
                this._map = new mapboxgl.Map(mapJSON);
                this._map.once('idle', resolve);
            } else if (mode === 'geojson') {
                const sourceLayers = this._getGeoJsonSources();
                const layers = this._getGeoJsonLayers();

                const mapJSON: MapboxOptions = {
                    container,
                    center: [0, 0],
                    zoom: 20,
                    dragRotate: false,
                    style: {
                        version: 8,
                        sprite: `${process.env.REACT_APP_URL_ASSETS_BASE}/sprite/sprite`,
                        sources: sourceLayers,
                        layers: layers,
                    },
                };

                mapboxgl.accessToken = this._token;
                this._map = new mapboxgl.Map(mapJSON);
                this._map.once('idle', resolve);
            }
        });
    }

    destroyMap() {
        const map = this._map;
        if (map) {
            const container = map.getContainer();
            map.remove();
            container.remove();
        }

        this._reset();
    }

    /**
     * @param id Id used to connect source and columns, as well as a
     * key by which to retrieve data from the map */
    getSL(id: string) {
        return this._sources[id].summaryLevel.id;
    }

    /**
     * @param id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param boundary polygon which will be used
     * to query data from the map.
     */
    async fetchDataFromMap(id: string, boundary: Boundary) {
        const map = this._map;
        if (!map) {
            throw new Error(
                'Map is not created. Please call `createMap` first',
            );
        }
        await this._fitBounds(boundary);

        const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [];

        // Query the central point
        const isPolygon = this._isPolygon[id];
        if (isPolygon) {
            if (!boundary.properties) {
                throw new Error('Properties on boundary are undefined');
            }
            const { layerId } = this._sources[id];

            const projectedPoint = map.project(boundary.properties.point);
            // Query features from map and filter only the results that has data
            const queryFeatures = map
                .queryRenderedFeatures(projectedPoint, {
                    layers: [layerId],
                })
                .filter(
                    (f) => f.properties && Object.keys(f.properties).length,
                );
            features.push(...queryFeatures);
        }

        const coordinates = boundary.geometry.coordinates[0];

        // create turf polygon from the projected user selection
        const turfPolygon = polygon([coordinates]);

        const layerId = isPolygon
            ? `${this._sources[id].layerId}p`
            : this._sources[id].layerId;

        // get all the features in the viewport
        // Query features from map and filter only the results that has data
        const queryFeatures = map
            .queryRenderedFeatures(undefined, {
                layers: [layerId],
            })
            .filter((f) => f.properties && Object.keys(f.properties).length);

        // Create the FeatureCollection object that is used by the pointsWithinPolygon function
        const pointsList: FeatureCollection<TurfPoint> = {
            type: 'FeatureCollection',
            features: queryFeatures.map((f) => {
                if (f.geometry.type === 'Point') {
                    const { coordinates } = f.geometry;

                    const p: TurfPoint = {
                        type: 'Point',
                        coordinates: [coordinates[0], coordinates[1]],
                    };

                    const x: Feature<TurfPoint> = {
                        type: 'Feature',
                        properties: f.properties,
                        geometry: p,
                    };
                    return x;
                }
                throw new Error('Type of the received geometry is not point.');
            }),
        };

        // Do the math
        const featuresInPolygon = pointsWithinPolygon(pointsList, turfPolygon);

        features.push(...featuresInPolygon.features);

        return this._mapFeatureToData(id, features);
    }

    async fetchGeoJsonDataFromMap(id: string, boundary: Boundary) {
        const map = this._map;
        if (!map) {
            throw new Error(
                'Map is not created. Please call `createMap` first',
            );
        }
        await this._fitBounds(boundary);

        const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
        const layer_id = this._geoJsonLayers.find(
            (layer) => layer.id === `facilities_${id}`,
        )!.id;

        // Query the central point
        const isPolygon = this._isPolygon[id];
        if (isPolygon) {
            if (!boundary.properties) {
                throw new Error('Properties on boundary are undefined');
            }

            const projectedPoint = map.project(boundary.properties.point);
            // Query features from map and filter only the results that has data
            const queryFeatures = map
                .queryRenderedFeatures(projectedPoint, {
                    layers: [layer_id],
                })
                .filter(
                    (f) => f.properties && Object.keys(f.properties).length,
                );
            features.push(...queryFeatures);
        }

        const coordinates = boundary.geometry.coordinates[0];

        // create turf polygon from the projected user selection
        const turfPolygon = polygon([coordinates]);

        const layerId: string = layer_id;

        // get all the features in the viewport
        // Query features from map and filter only the results that has data
        const queryFeatures = map
            .queryRenderedFeatures(undefined, {
                layers: [layerId],
            })
            .filter((f) => f.properties && Object.keys(f.properties).length);

        // Create the FeatureCollection object that is used by the pointsWithinPolygon function
        const pointsList: FeatureCollection<TurfPoint> = {
            type: 'FeatureCollection',
            features: queryFeatures.map((f) => {
                if (f.geometry.type === 'Point') {
                    const { coordinates } = f.geometry;

                    const p: TurfPoint = {
                        type: 'Point',
                        coordinates: [coordinates[0], coordinates[1]],
                    };

                    const x: Feature<TurfPoint> = {
                        type: 'Feature',
                        properties: f.properties,
                        geometry: p,
                    };
                    return x;
                }
                throw new Error('Type of the received geometry is not point.');
            }),
        };

        // Do the math

        const featuresInPolygon = pointsWithinPolygon(pointsList, turfPolygon);

        features.push(...featuresInPolygon.features);

        return features;
    }

    setGeoJsonSources(id: string, sourceData: mapboxgl.AnySourceData) {
        if (this._geoJsonSources[id] != null) {
            throw new Error('Source with given ID already exists!');
        }
        this._geoJsonSources[id] = sourceData;
    }

    setGeoJsonLayers(sourceId: string, layerId: string) {
        if (this._geoJsonLayers.some((layer) => layer.id === layerId)) {
            throw new Error('Layer with given ID already exists!');
        }
        this._geoJsonLayers.push({
            id: layerId,
            type: 'circle',
            source: sourceId,
            layout: {},
            paint: {
                'circle-radius': 6,
                'circle-stroke-width': 1,
                'circle-color': '#000000',
            },
        });
    }

    /**
     *
     * @param id used to connect source and columns, as well as a
     * key by which to retrieve data from the map
     * @param point which will be used to query data from the map.
     * @param boundary that is used to fit the map bounds to make sure that the point will be rendered on the map.
     */
    async fetchPointDataFromMap(
        id: string,
        point: Point,
        boundary?: Boundary | null,
    ) {
        const map = this._map;
        if (!map) {
            throw new Error(
                'Map is not created. Please call `createMap` first',
            );
        }

        if (boundary) {
            await this._fitBounds(boundary);
        }

        const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
        const { layerId } = this._sources[id];
        const projectedPoint = map.project(point);
        const queryFeatures = map.queryRenderedFeatures(projectedPoint, {
            layers: [layerId],
        });

        features.push(...queryFeatures);

        return this._mapFeatureToData(id, features);
    }

    /**
     * @param id
     * @param queryFeatures
     * @returns Key is column name. Value is value
     * retrieved from data tiles. Type of the value depends on column type.
     */
    _mapFeatureToData(
        id: string,
        queryFeatures: GeoJSON.Feature<GeoJSON.Geometry>[],
    ): MapQueryResponse[] {
        const columns = this._columns[id];
        return queryFeatures.map((feature) =>
            columns.reduce((memo: MapQueryResponse, { columnName }) => {
                if (feature.properties) {
                    memo[columnName] = feature.properties[columnName];
                }
                return memo;
            }, {}),
        );
    }

    _getGeoJsonSources() {
        return this._geoJsonSources;
    }

    _getGeoJsonLayers() {
        return this._geoJsonLayers;
    }

    /**
     * Fits map to provided boundary
     */
    _fitBounds(boundary: Boundary) {
        const map = this._map;
        if (!map) {
            throw new Error(
                'Map is not created. Please call `createMap` first',
            );
        }
        return new Promise((resolve) => {
            const boundingBox = this._getFeatureBoundingBox([boundary]);

            const fitBoundsOptions = {
                duration: 0,
                animate: false,
                padding: 10,
            };

            map.fitBounds(boundingBox, fitBoundsOptions);
            map.once('idle', resolve);
        });
    }

    _getFeatureBoundingBox = (
        features: Boundary[],
    ): [LngLatLike, LngLatLike] => {
        const bounds: BoundingBox = {
            xMin: Infinity,
            xMax: -Infinity,
            yMin: Infinity,
            yMax: -Infinity,
        };

        for (let i = 0; i < features.length; i += 1) {
            const coords = features[i].geometry.coordinates[0];

            for (let j = 0; j < coords.length; j += 1) {
                const longitude = coords[j][0];
                const latitude = coords[j][1];

                bounds.xMin = Math.min(bounds.xMin, longitude);
                bounds.xMax = Math.max(bounds.xMax, longitude);
                bounds.yMin = Math.min(bounds.yMin, latitude);
                bounds.yMax = Math.max(bounds.yMax, latitude);
            }
        }

        return [
            [bounds.xMin, bounds.yMin],
            [bounds.xMax, bounds.yMax],
        ];
    };

    _getGeobufferDatasetIdAndColumns(id: string): SourceLayerDataset[] {
        // to be sure that we don't have duplicate features queried (eg. get
        // feature where the center of the radius is, and then again get same
        // feature by polygon/circle), we need to always get fips column.
        // That column will be used in fetchDataFromMap for de-duplication
        const columns = this._columns[id];
        const [fipsGeoColumn] = this.getGeoColumnNames(id);
        const fipsColumnFromColumns = columns.find(
            ({ columnName, datasetAbbreviation }) =>
                fipsGeoColumn.columnName === columnName &&
                fipsGeoColumn.datasetAbbreviation === datasetAbbreviation,
        );
        // add fips column only if developer didn't add it already
        if (!fipsColumnFromColumns) {
            columns.push(fipsGeoColumn);
        }

        const source = this._sources[id];

        const columnsByDatasetAbbreviation = columns.reduce(
            (memo: { [datasetAbbreviation: string]: string[] }, value) => {
                const { datasetAbbreviation, columnName } = value;
                if (!memo[datasetAbbreviation]) {
                    memo[datasetAbbreviation] = [];
                }
                memo[datasetAbbreviation].push(columnName);
                return memo;
            },
            {},
        );

        return Object.keys(columnsByDatasetAbbreviation).map(
            (datasetAbbreviation) => {
                const geobufferDataset = Object.values(source.datasets).find(
                    (dataset) =>
                        dataset.datasetAbbreviation === datasetAbbreviation,
                );

                if (!geobufferDataset) {
                    throw new Error(
                        `Dataset ${datasetAbbreviation} not present in provided datasets`,
                    );
                }

                return {
                    datasetId: geobufferDataset.id.toString(),
                    columns: columnsByDatasetAbbreviation[datasetAbbreviation],
                };
            },
        );
    }

    _getSourceLayers() {
        const layers: SourceLayer[] = [];
        Object.keys(this._sources).forEach((id) => {
            const source = this._sources[id];
            const isPolygon = this._isPolygon[id];
            // Check if the source layer exist. This can happen if surveys being
            // compared (multi year) are using the same geo layers like in the
            // case of EASI data
            const layerExists = layers.some(
                (el) => el.layerId === source.layerId,
            );
            if (layerExists) return;

            const datasets = this._getGeobufferDatasetIdAndColumns(id);
            layers.push({
                layerId: source.layerId,
                datasets,
            });
            if (isPolygon) {
                layers.push({
                    layerId: `${source.layerId}p`,
                    datasets,
                });
            }
        });
        return layers;
    }

    /**
     * @param layerId geo layer id from geobuffer
     */
    _createPointPresentationLayer(layerId: string): mapboxgl.SymbolLayer {
        return {
            id: layerId,
            source: SOURCE_NAME,
            'source-layer': layerId,
            type: 'symbol',
            layout: {
                'icon-image': 'circle',
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-padding': 0,
                'icon-size': 0.5,
            },
        };
    }

    /**
     * @param layerId geo layer id
     */
    _createFillPresentationLayer(layerId: string): mapboxgl.FillLayer {
        return {
            id: layerId,
            source: SOURCE_NAME,
            'source-layer': layerId,
            type: 'fill',
            paint: {
                'fill-outline-color': '#FF0000',
                'fill-color': '#111111',
                'fill-opacity': 0.15,
            },
        };
    }

    _getPresentationLayers() {
        const layers: (mapboxgl.FillLayer | mapboxgl.SymbolLayer)[] = [];
        Object.keys(this._sources).forEach((id) => {
            const source = this._sources[id];
            const isPolygon = this._isPolygon[id];
            // Check if the presentation layer exist. This can happen if surveys
            // being compared (multi year) are using the same geo layers like in
            // the case of EASI data
            const layerExists = layers.some(
                (layer) => layer.id === source.layerId,
            );
            if (layerExists) return;
            if (isPolygon) {
                layers.push(
                    this._createFillPresentationLayer(source.layerId),
                    this._createPointPresentationLayer(`${source.layerId}p`),
                );
            } else {
                layers.push(this._createPointPresentationLayer(source.layerId));
            }
        });
        return layers;
    }

    _getTiles(sourceLayers: MapSourceLayer[]) {
        const tilesTemplate = process.env.REACT_APP_URL_TILES;
        const tileLayerIds = sourceLayers
            .map((sourceLayer) => sourceLayer.layerId)
            .join(',');
        const columns = sourceLayers.flatMap((sourceLayer) =>
            sourceLayer.datasets.flatMap((dataset) =>
                dataset.columns.map(
                    (columnName) =>
                        `${sourceLayer.layerId}.${dataset.datasetId}.${columnName}`,
                ),
            ),
        );
        // sourceLayerId.datasetId.columnName // 144582.0.Geo_Name
        const tileColumns = columns.join(',');
        return tilesTemplate
            .replace('{layers}', tileLayerIds)
            .replace('{columns}', tileColumns);
    }
}
