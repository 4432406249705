import classNames from 'classnames';
import useQueryParams from '../hooks/useQueryParams';
import { getProfileUnit } from '../enums/AnalysisProfile';

const SectionsHeader = () => {
    const { profile, selection } = useQueryParams();
    const noOfSelections = selection.length;

    if (noOfSelections <= 1) {
        return null;
    }
    const colNumber = 12 / noOfSelections;

    return (
        <div className="row mx-0">
            {selection.map((s, index) => {
                return (
                    <div
                        key={s}
                        className={`col-${colNumber} py-12 px-0 border-bottom`}
                    >
                        <h5
                            className={classNames('fw-bold text-capitalize', {
                                'border-start': index > 0,
                            })}
                        >
                            {s} {getProfileUnit([s], profile)}
                        </h5>
                    </div>
                );
            })}
        </div>
    );
};

export default SectionsHeader;
