import { ColorRGB } from '../types';

export const WHITE: ColorRGB = [255, 255, 255];

export const GRAY10: ColorRGB = [245, 245, 245];

export const GRAY20: ColorRGB = [235, 235, 235];

export const GRAY40: ColorRGB = [177, 177, 177];

export const GRAY50: ColorRGB = [117, 117, 117];

export const GRAY70: ColorRGB = [97, 97, 97];

export const GRAY90: ColorRGB = [33, 33, 33];

export const BLACK: ColorRGB = [0, 0, 0];

export const GREEN_DARK: ColorRGB = [148, 192, 184];

export const GREEN: ColorRGB = [76, 150, 137];

export const GREEN_LIGHT: ColorRGB = [222, 237, 234];

export const RED_DARK: ColorRGB = [243, 147, 157];

export const RED: ColorRGB = [237, 77, 92];

export const RED_LIGHT: ColorRGB = [250, 223, 226];

export const TRACTIQ_PRIMARY_DARK: ColorRGB = [11, 10, 56];

export const TRACTIQ_SECONDARY_DARK: ColorRGB = [10, 4, 50];

export const BLUE_DARK: ColorRGB = [18, 87, 221];
