import mapboxgl, { AnyLayer, Map, LngLatBoundsLike } from 'mapbox-gl';
import {
    LayerLibrary,
    MapBoxIsochroneResponse,
    MapQueryResponse,
    Point as Center,
    Profile,
    TurfCircleOptions,
    MapQueriedPointsBySurveyByUda,
} from '../types';
import { FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import * as turf from '@turf/turf';
import { RGBAData } from 'jspdf';
import { getContext } from './context';
import { Feature } from '@turf/turf';
import { FacilityType } from '../pages/selfstorage-point-info/enum/FacilityType';
/**
 *
 * @param layerId
 * @param columns
 * @returns
 */
const prepareColumnsForUrl = (
    layerId: string | undefined,
    columns: string[] | undefined,
) => {
    let joinedColumns;
    if (columns) {
        joinedColumns = columns.join(`,${layerId}.0.`);
    }
    return `${layerId}.0.${joinedColumns}&v=1`;
};

/**
 *
 * @param layerId
 * @param urlColumns
 * @returns
 */
const getLibrarySource = (
    layerId: string | undefined,
    urlColumns: string | undefined,
): mapboxgl.VectorSource | undefined => {
    if (layerId && urlColumns) {
        return {
            type: 'vector',
            tiles: [
                `https://tiles3.socialexplorer.com/gettile/?x={x}&y={y}&z={z}&layers=${layerId}&projection=EPSG-3857&columns=${urlColumns}`,
            ],
        };
    }
};

/**
 *
 * @param point
 * @param mapPin
 * @param profile
 * @param selection
 * @param layers
 * @param geojson
 * @param pointsBySurveyByUda
 * @returns
 */
const createPdfMap = async (
    point: Center,
    mapPin:
        | string
        | HTMLImageElement
        | HTMLCanvasElement
        | Uint8Array
        | RGBAData,
    profile: Profile,
    selection: number[],
    layers: LayerLibrary.Item[],
    geojson: FeatureCollection<Point, GeoJsonProperties>,
    pointsBySurveyByUda: MapQueriedPointsBySurveyByUda,
) => {
    mapboxgl.accessToken = getContext().mapboxAccessToken;
    if (!layers) {
        console.error('ERROR: Could not fetch library layers');
    }
    const traficLayer = layers.find(
        (layer: LayerLibrary.Item) => layer.id === 'traffic_count',
    );

    const housingStartsLayer = layers.find(
        (layer: LayerLibrary.Item) => layer.id === 'housing_starts',
    );

    if (!traficLayer || !housingStartsLayer) {
        console.error('ERROR: No layers found');
        return;
    }

    const facilities: MapQueryResponse[] = [];

    facilities.push(
        ...pointsBySurveyByUda['PACCOM2020'][selection[selection.length - 1]]
            .features,
    );

    facilities.sort(
        (a: MapQueryResponse, b: MapQueryResponse) => a.distance - b.distance,
    );

    const sitesGeoJsonData: FeatureCollection<Point, GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: facilities.map((feature: MapQueryResponse, idx: number) => {
            return {
                type: 'Feature',
                geometry: {
                    coordinates: [feature.lng, feature.lat],
                    type: 'Point',
                },
                properties: {
                    index: idx + 1,
                },
            } as Feature<Point, GeoJsonProperties>;
        }),
    };

    const housingStartsColumns =
        housingStartsLayer.sources.socialexplorerlibrarysource.layers[0]
            .datasets[0].columns;
    let housingStartsUrl = prepareColumnsForUrl(
        housingStartsLayer.sources.socialexplorerlibrarysource.layers[0]
            .layerId,
        housingStartsColumns,
    );
    const housingStartsSourceData = getLibrarySource(
        housingStartsLayer.sources.socialexplorerlibrarysource.layers[0]
            .layerId,
        housingStartsUrl,
    );

    const traficColumns =
        traficLayer.sources.socialexplorerlibrarysource.layers[0].datasets[0]
            .columns;
    const traficeUrl = prepareColumnsForUrl(
        traficLayer.sources.socialexplorerlibrarysource.layers[0].layerId,
        traficColumns,
    );
    const traficSourceData = getLibrarySource(
        traficLayer.sources.socialexplorerlibrarysource.layers[0].layerId,
        traficeUrl,
    );

    return new Promise<mapboxgl.Map>((resolve) => {
        const container = document.createElement('div');
        container.classList.add('export-map-container');
        document.body.appendChild(container);

        const mapInstance: mapboxgl.Map = new mapboxgl.Map({
            container: container,
            style: 'mapbox://styles/mapbox/streets-v12',
            fitBoundsOptions: {
                padding: 10,
            },
        });

        mapInstance.jumpTo({
            center: [point.lng, point.lat],
            zoom: 12.5,
        });
        const scale = new mapboxgl.ScaleControl({
            maxWidth: 80,
            unit: 'imperial',
        });
        mapInstance.addControl(scale);

        mapInstance.once('load', async function () {
            if (traficSourceData) {
                mapInstance.addSource('aadt_us_2022', traficSourceData);
            }
            mapInstance.addSource('self_storage_units', {
                type: 'geojson',
                data: geojson as any,
            });
            mapInstance.addSource('self_storage_units_count', {
                type: 'geojson',
                data: sitesGeoJsonData,
            });

            if (housingStartsSourceData) {
                mapInstance.addSource(
                    'housing_starts',
                    housingStartsSourceData,
                );
            }

            if (traficLayer) {
                const traficLayers = traficLayer.layers;
                traficLayers.forEach(
                    (layer: LayerLibrary.LayersWithMetadata) => {
                        const newLayer = layer;
                        newLayer.source = 'aadt_us_2022';
                        newLayer.layout = {
                            visibility: 'visible',
                        };
                        mapInstance.addLayer(newLayer as mapboxgl.AnyLayer);
                    },
                );
            }
            const HOUSING_STARTS_CIRCLES = [20, 30, 40];
            if (housingStartsLayer) {
                const housingStartsLayers = housingStartsLayer.layers;
                housingStartsLayers.forEach(
                    (layer: LayerLibrary.LayersWithMetadata) => {
                        const newLayer = layer;
                        newLayer.source = 'housing_starts';
                        newLayer.layout = {
                            visibility: 'visible',
                        };
                        newLayer.type = 'circle';
                        // Circle radius depends on hasUnits and hasBeds variables
                        // If hasUnits is -1, we check hasBeds
                        // If value is <= 500, set radius to 15
                        // If value is > 500 and <= 1000, set radius to 20
                        // If value is > 1000, set radius to 25
                        newLayer.paint = {
                            'circle-stroke-width': 3,
                            'circle-stroke-color': 'white',
                            'circle-radius': [
                                'case',
                                ['==', ['get', 'hasUnits'], -1],
                                [
                                    'case',
                                    ['<=', ['get', 'hasBeds'], 500],
                                    HOUSING_STARTS_CIRCLES[0],
                                    [
                                        'all',
                                        ['>', ['get', 'hasBeds'], 500],
                                        ['<=', ['get', 'hasBeds'], 1000],
                                    ],
                                    HOUSING_STARTS_CIRCLES[1],
                                    ['>', ['get', 'hasBeds'], 1000],
                                    HOUSING_STARTS_CIRCLES[2],
                                    HOUSING_STARTS_CIRCLES[0],
                                ],
                                [
                                    'case',
                                    ['<=', ['get', 'hasUnits'], 500],
                                    HOUSING_STARTS_CIRCLES[0],
                                    [
                                        'all',
                                        ['>', ['get', 'hasUnits'], 500],
                                        ['<=', ['get', 'hasUnits'], 1000],
                                    ],
                                    HOUSING_STARTS_CIRCLES[1],
                                    ['>', ['get', 'hasUnits'], 1000],
                                    HOUSING_STARTS_CIRCLES[2],
                                    HOUSING_STARTS_CIRCLES[0],
                                ],
                            ],

                            'circle-color': '#d7b482',
                            'circle-opacity': 0.6,
                        };
                        mapInstance.addLayer(newLayer as mapboxgl.AnyLayer);
                    },
                );
            }

            mapInstance.loadImage(mapPin.toString(), (error, image) => {
                if (error || !image) throw error;
                mapInstance.addImage('custom-marker', image);
                mapInstance.addSource('points', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: [
                            {
                                // feature for Mapbox SF
                                type: 'Feature',
                                geometry: {
                                    type: 'Point',
                                    coordinates: [point.lng, point.lat],
                                },
                                properties: {},
                            },
                        ],
                    },
                });

                // Add a symbol layer
                mapInstance.addLayer({
                    id: 'points',
                    type: 'symbol',
                    source: 'points',
                    layout: {
                        'icon-image': 'custom-marker',
                        'icon-size': 0.75,
                    },
                });
            });
            const AREA_COLORS = ['#ED6A5E', '#F6BD4F', '#29CBC5'];
            // Add isochrone or radius depending on analysis type
            if (profile === 'radius') {
                const center = [point.lng, point.lat];
                const options: TurfCircleOptions = {
                    steps: 100,
                    units: 'miles',
                    properties: {},
                };
                selection.forEach((radius, idx) => {
                    const circle = turf.circle(center, radius, options);

                    const line = turf.lineString(
                        circle.geometry.coordinates[0],
                    );
                    const bbox = turf.bbox(circle);
                    mapInstance.addSource(`radius_${radius}`, {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [line],
                        },
                    });

                    // Add a symbol layer
                    mapInstance.addLayer({
                        id: `radius_${radius}`,
                        type: 'line',
                        source: `radius_${radius}`,
                        layout: {},
                        paint: {
                            'line-color': AREA_COLORS[idx],
                            'line-width': 6,
                            'line-opacity': 1,
                        },
                    });

                    mapInstance.fitBounds(bbox as LngLatBoundsLike, {
                        padding: 60,
                    });
                });
            } else {
                const mapBoxResponses: Promise<MapBoxIsochroneResponse>[] = [];
                let isochrones: MapBoxIsochroneResponse[] = [];
                selection.forEach((minutes) => {
                    mapBoxResponses.push(
                        getMapBoxIsochrone(minutes, profile, point),
                    );
                });
                await Promise.all(mapBoxResponses).then(
                    (result) => (isochrones = [...result]),
                );
                selection.forEach((radius, idx) => {
                    const bbox = turf.bbox(isochrones[idx]);
                    mapInstance.addSource(`radius_${radius}`, {
                        type: 'geojson',
                        data: isochrones[idx],
                    });

                    // Add a symbol layer
                    mapInstance.addLayer({
                        id: `radius_${radius}`,
                        type: 'line',
                        source: `radius_${radius}`,
                        layout: {},
                        paint: {
                            'line-color': AREA_COLORS[idx],
                            'line-width': 6,
                            'line-opacity': 1,
                        },
                    });

                    mapInstance.fitBounds(bbox as LngLatBoundsLike, {
                        padding: 20,
                    });
                });
            }

            mapInstance.addLayer({
                id: 'selfstorage-layer',
                type: 'circle',
                source: 'self_storage_units',
                paint: {
                    'circle-radius': 15,
                    'circle-stroke-width': 6,
                    'circle-stroke-color': 'white',
                    'circle-color': [
                        'match',
                        ['to-string', ['get', 'category']],
                        FacilityType.PRICING,
                        '#619D7A', // green
                        FacilityType.NOPRICING,
                        '#D2696A',
                        FacilityType.CONSTRUCTION,
                        '#F1B82B',
                        '#D2696A',
                    ],
                },
            });
            mapInstance.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'self_storage_units_count',
                layout: {
                    'text-field': ['get', 'index'],
                    'text-size': 16,
                },
                paint: {
                    'text-color': 'white',
                },
            });
        });

        mapInstance.once('idle', () => {
            resolve(mapInstance);
        });
    });
};

/**
 * Shows or hides the layers which are linked to the source with id provided as param
 * @param map Map that we modify
 * @param source Id of source. All layers with their source equal to this will be toggled
 * @param hide If true, hides layers. If false, shows layers
 * @returns Map with modified layer visibility
 */
export const toggleLayerVisibilityBySource = async (
    map: Map,
    source: string,
    hide: boolean = true,
) => {
    return new Promise<mapboxgl.Map>((resolve) => {
        const layersToToggle: string[] = [];
        let mapStyle = map.getStyle();
        if (mapStyle.layers) {
            mapStyle.layers.forEach((layer: AnyLayer) => {
                if ('source' in layer && layer.source === source) {
                    layersToToggle.push(layer.id);
                }
            });
        }

        layersToToggle.forEach((layerId) => {
            map.setLayoutProperty(
                layerId,
                'visibility',
                hide ? 'none' : 'visible',
            );
        });
        map.once('idle', () => {
            resolve(map);
        });
    });
};

const getMapBoxIsochrone = async (
    minutes: number,
    profile: string,
    point: Center,
): Promise<MapBoxIsochroneResponse> => {
    return new Promise<MapBoxIsochroneResponse>((resolve) => {
        fetch(
            `${process.env.REACT_APP_URL_BACKEND_RAILS}/contours?lng=${point.lng}&lat=${point.lat}&type=${profile}&values=${minutes}&polygons=true`,
        ).then((response) => {
            resolve(response.json());
        });
    });
};

export default createPdfMap;
