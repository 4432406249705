import { useState, useEffect } from 'react';
import { OmniWebService, User } from '../types';
import hotjar from '../helpers/Hotjar';
import { hasValidSubscription } from '../helpers/subscription';
import Mixpanel from '../helpers/mixpanel';

const useCurrentUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<null | any>(null);
    const [user, setUser] = useState<null | User>(null);

    useEffect(() => {
        const getCurrentUser = async () => {
            setIsLoading(true);
            // fetch me
            try {
                const userResponse = await fetch(
                    `${process.env.REACT_APP_URL_BACKEND_ACCOUNTS}/whoami`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    },
                );
                if (!userResponse.ok) {
                    throw new Error('Problem fetching current user');
                }
                const whoAmI: OmniWebService.WhoAmIResponse = await userResponse.json();
                const sessionResponse = await fetch(
                    `${process.env.REACT_APP_URL_RAILS_SESSION}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    },
                );
                if (!sessionResponse.ok) {
                    throw new Error(
                        'Problem fetching subscriptions for current user',
                    );
                }
                const licenses: OmniWebService.UserSession = await sessionResponse.json();
                const user: User = {
                    whoami: whoAmI,
                    licenses,
                };
                // track the user through Mixpanel
                Mixpanel.identifyAndSetUser(`${whoAmI.id}`, `${whoAmI.firstName} ${whoAmI.lastName}`, whoAmI.email);

                setUser(user);
                // At the moment visual reports are only available for tractiq and selfstorage
                if (hasValidSubscription(user)) {
                    hotjar.identifyUser(whoAmI?.id, 'Professional plan');

                    hotjar.startRecording();
                }
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };
        getCurrentUser();
    }, []);

    return { isLoading, error, user };
};

export default useCurrentUser;
