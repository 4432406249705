import classNames from 'classnames';
import {
    SectionVisualizationListTable,
    SiteAnalysisBase,
} from '../../../types';
import { formatValue } from '../../../helpers/ValueFormatter';
import ValueFormat from '../../../enums/ValueFormat';
import ChartInfo from '../../../components/ChartInfo';

type Props = {
    data: SiteAnalysisBase;
    visualization: SectionVisualizationListTable;
    showAboutThisData: boolean;
};
const ListTableSection = ({
    data,
    visualization,
    showAboutThisData,
}: Props) => {
    const values = data[visualization.variable] as [];

    const style = {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
    };
    const aboutThisDataClasses = classNames(
        'row px-16 px-md-32 mb-14 mx-0 align-items-center',
        { 'py-4 mb-2': !showAboutThisData },
    );

    return (
        <>
            {visualization.title && (
                <h5 className="text-start mx-20 mt-36">
                    {visualization.title}
                </h5>
            )}
            <div className="text-end mx-20 mt-12 d-flex">
                {visualization.columns.map((column, idx) => {
                    const isFirstColumn = idx === 0;
                    const headerClasses = classNames(
                        'd-flex align-items-end opacity-062 text-black',
                        {
                            'px-12 col-6 text-start': isFirstColumn,
                            'px-6 col-3 justify-content-end': !isFirstColumn,
                        },
                    );
                    return (
                        <p key={idx} className={headerClasses}>
                            {column.title}
                        </p>
                    );
                })}
            </div>
            <div className="my-12 border rounded-2 mx-20">
                {values.map((row, rowIdx) => {
                    const rowClasses = classNames('row mx-0 py-4 gx-0', {
                        'rounded-1':
                            rowIdx === 0 || rowIdx === values.length - 1,
                    });

                    return (
                        <div
                            key={rowIdx}
                            className={rowClasses}
                            style={rowIdx % 2 === 0 ? style : {}}
                        >
                            {visualization.columns.map((column, colIdx) => {
                                const isFirstColumn = colIdx === 0;
                                const columnClasses = classNames({
                                    'col-6 text-start opacity-062 text-black ps-12': isFirstColumn,
                                    'col-3 text-end text-black pe-12': !isFirstColumn,
                                });

                                const format: ValueFormat =
                                    column.format ?? visualization.valueFormat;
                                const value = row[column.variable];
                                const formattedValue = formatValue(
                                    value,
                                    format,
                                );

                                const varClasses = classNames(columnClasses, {
                                    'fst-italic opacity-062':
                                        !formattedValue ||
                                        formattedValue === 'N/A',
                                });

                                return (
                                    <div
                                        key={`row${rowIdx}col${colIdx}`}
                                        className={varClasses}
                                    >
                                        {formattedValue || 'N/A'}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <div className={aboutThisDataClasses}>
                {showAboutThisData && (
                    <div className="col-auto px-0">
                        <ChartInfo
                            visualizationIds={[visualization.id]}
                        ></ChartInfo>
                    </div>
                )}
            </div>
        </>
    );
};

export default ListTableSection;
