import { getGoogleMapsLink } from '../helpers/getGoogleMapsLink';
import useNearestAddress from '../hooks/useNearestAddress';
import useQueryParams from '../hooks/useQueryParams';
import { ReactComponent as GooglePinImage } from '../assets/google-maps-pin.svg';
import { SelfStoragePointHeaderInfo } from '../types';
type Props = {
    locationType: SelfStoragePointHeaderInfo | undefined;
};

const Location = ({ locationType }: Props) => {
    const { point, title } = useQueryParams();
    const { isLoading, address } = useNearestAddress(point.lat, point.lng);

    const titleText = title ?? 'Report Title';
    return (
        <div className="row bg-backgroundSmoke border-top border-bottom py-24 mx-0">
            {locationType && (
                <div className="row text-start text-truncate mb-16 px-16 px-md-32 gx-8">
                    <div className="col-auto">
                        <h6
                            className="text-start text-truncate color-black opacity-062 text-uppercase"
                            title={locationType.name ?? ''}
                        >
                            {locationType.name}
                        </h6>
                    </div>
                    <div className="col-auto">
                        <h6
                            className="text-start text-truncate px-8 bg-backroundLightGray color-black opacity-062 text-uppercase"
                            title={locationType.type ?? ''}
                        >
                            {locationType.type}
                        </h6>
                    </div>
                </div>
            )}
            <h3
                className="text-start text-truncate mb-16 px-16 px-md-32"
                title={titleText}
            >
                {titleText}
            </h3>
            <div className="d-flex flex-column flex-md-row text-start fs-6 px-16 px-md-32 align-items-top">
                <div className="d-flex flex-row">
                    <div className="me-8">
                        <GooglePinImage className="google-pin-img" />
                    </div>
                    <div className="pt-1 opacity-087 text-truncate">
                        <a
                            className="text-decoration-none text-black text-decoration-underline"
                            target={'_blank'}
                            rel="noreferrer"
                            href={getGoogleMapsLink(point.lat, point.lng)}
                        >
                            {isLoading ? 'Loading...' : address}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
