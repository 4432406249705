import { useContext } from 'react';
import { Context } from '../../helpers/context';

const AccessRequiredMessage = () => {
    const { dashboardUrl } = useContext(Context);
    return (
        <div className="text-start bg-backgroundSmoke d-flex align-items-center min-vh-100 ">
            <div className="container d-flex justify-content-center">
                <div>
                    <div className="row">
                        <h2>You Need Access</h2>
                    </div>
                    <div className="row mt-24">
                        <span>
                            <p>
                                Upgrade your account, or switch to an account
                                with
                            </p>
                            <p>
                                access to view and download this report and get
                            </p>
                            <p>
                                unlimited access to TractIQ SelfStorage tools
                                and data.
                            </p>
                        </span>
                    </div>
                    <div className="row mt-48">
                        <div className="col">
                            <a
                                className="btn px-16 border border-gray opacity-75 fw-bold me-12 mb-8"
                                href={dashboardUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Open Homepage
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessRequiredMessage;
