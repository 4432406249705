import {
    SiteAnalysis,
    SectionVisualizations,
    SiteAnalysisBase,
} from '../types';
import useQueryParams from '../hooks/useQueryParams';

import UdaSection from './UdaSection';

type Props = {
    selectedSelection: number | null;
    section: SectionVisualizations;
    data: SiteAnalysis[];
};

const SectionComponent = ({ selectedSelection, section, data }: Props) => {
    const { selection } = useQueryParams();

    const udaSections = selection.map((s) => {
        const selectionData = data.find(
            (selectionOlapData) => selectionOlapData.selection === s,
        );

        if (!selectionData) {
            return <div>No selection data</div>;
        }
        return (
            <UdaSection
                key={s}
                selection={s}
                selectedSelection={selectedSelection}
                visualizations={section}
                data={selectionData.data as SiteAnalysisBase}
            />
        );
    });

    return <div className="row mx-0 bg-white">{udaSections}</div>;
};

export default SectionComponent;
