import { useState, useEffect } from 'react';

import * as DB from '../helpers/db';
import { HereGeocoderResponse } from '../types';

const useNearestAddress = (lat: number, lng: number) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [address, setAddress] = useState<string>('Custom Location');

    useEffect(() => {
        let isSubscribed = true;
        const getNearestAddress = async () => {
            setIsLoading(true);
            try {
                const url = process.env.REACT_APP_URL_HERE_REV_API.replace(
                    '{lat}',
                    lat.toString(),
                )
                    .replace('{lng}', lng.toString())
                    .replace(
                        '{hereapikey}',
                        `${process.env.REACT_APP_HERE_API_KEY}`,
                    );
                let address: string | null;
                address = await DB.get('here', url);
                if (!address) {
                    const response = await fetch(url);
                    if (!response.ok) {
                        address = 'Custom Location';
                    } else {
                        const data: HereGeocoderResponse = await response.json();
                        if (
                            data.items.length !== 0 &&
                            data.items[0].resultType !== 'administrativeArea'
                        ) {
                            address = data.items[0].address.label;
                        } else {
                            address = 'Custom Location';
                        }
                    }
                    await DB.set('here', url, address);
                }

                if (isSubscribed) {
                    setAddress(address);
                    setIsLoading(false);
                }
            } catch (error) {
                if (!isSubscribed) return;
                setError('Problem fetching location');
                setIsLoading(false);
            }
        };
        getNearestAddress();
        return () => {
            isSubscribed = false;
        };
    }, [lat, lng]);

    return { isLoading, error, address };
};

export default useNearestAddress;
