import classNames from 'classnames';
import {
    LabelSeries,
    SectionVisualizationGroupedTable,
    TableGroup,
    SiteAnalysisBase,
} from '../../../types';
import { formatValue } from '../../../helpers/ValueFormatter';
import ValueFormat from '../../../enums/ValueFormat';
import { useEffect } from 'react';
import { Popover as BootstrapPopover } from 'bootstrap';
import ChartInfo from '../../../components/ChartInfo';

type Props = {
    data: SiteAnalysisBase;
    visualization: SectionVisualizationGroupedTable;
    showAboutThisData: boolean;
};
const GroupedTableSection = ({
    data,
    visualization,
    showAboutThisData,
}: Props) => {
    useEffect(() => {
        const popoverTriggerList = Array.from(
            document.querySelectorAll('[data-bs-toggle="popover"]'),
        );

        popoverTriggerList.map(function (popoverTriggerEl) {
            return new BootstrapPopover(popoverTriggerEl);
        });
    });
    const groupByName = visualization.labels.reduce(
        (group: TableGroup, label: LabelSeries) => {
            const { title } = label;
            group[title] = group[title] ?? [];
            group[title].push(label);
            return group;
        },
        {},
    );
    const objectKeys = Object.keys(groupByName);
    objectKeys.forEach((key: string) => {
        groupByName[key].forEach((group: LabelSeries, idx: number) => {
            const format: ValueFormat =
                group.format ?? visualization.valueFormat;

            const value = data[group.variable];
            const formattedValue = formatValue(value, format);
            group = { ...group, formattedValue: formattedValue ?? 'N/A' };
            groupByName[key][idx] = group;
        });
    });
    const style = {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
    };
    const aboutThisDataClasses = classNames(
        'row px-16 px-md-32 mb-14 mx-0 align-items-center',
        { 'py-4 pb-20': !showAboutThisData },
    );
    return (
        <>
            <div className="text-end mx-20 mt-36 d-flex">
                <p className="px-12 col-6 text-start d-flex align-items-end opacity-062 text-black">
                    {visualization.title}
                </p>
                {visualization.groups.map((group: string, idx) => {
                    return (
                        <p
                            className="px-6 col-3 opacity-062 text-black"
                            key={idx}
                        >
                            {group}
                        </p>
                    );
                })}
            </div>
            <div className="my-12 border rounded-2 mx-20">
                {objectKeys.map((key: string, idx: number) => {
                    const rowClasses = classNames('row mx-0 py-4 gx-0', {
                        'rounded-1':
                            idx === 0 || idx === groupByName[key].length - 1,
                    });
                    return (
                        <div
                            key={idx}
                            className={rowClasses}
                            style={idx % 2 === 0 ? style : {}}
                        >
                            <div className="col-6 text-start opacity-062 text-black ps-12">
                                {key}
                            </div>
                            <div className="col-6 text-end text-black opacity-100 pe-12 d-flex">
                                {visualization.groups.map((group, id) => {
                                    const variable = groupByName[key].find(
                                        (obj: LabelSeries) =>
                                            obj.group === group,
                                    );
                                    const varClasses = classNames('col-6', {
                                        'fst-italic':
                                            !variable ||
                                            !variable.formattedValue ||
                                            variable.formattedValue === 'N/A',
                                    });
                                    return (
                                        <p className={varClasses} key={id}>
                                            {variable
                                                ? variable.formattedValue
                                                : 'N/A'}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={aboutThisDataClasses}>
                {showAboutThisData && (
                    <div className="col-auto px-0">
                        <ChartInfo
                            visualizationIds={[visualization.id]}
                        ></ChartInfo>
                    </div>
                )}
            </div>
        </>
    );
};

export default GroupedTableSection;
