// Fonts
import bcBold from './BarlowCondensed-Bold.ttf';
import msRegular from './Montserrat-Regular.ttf';
import msBold from './Montserrat-Bold.ttf';
import msSemiBold from './Montserrat-SemiBold.ttf';
import sspRegular from './SourceSansPro-Regular.ttf';
import sspBold from './SourceSansPro-Bold.ttf';
import sspSemiBold from './SourceSansPro-SemiBold.ttf';
import sspItalic from './SourceSansPro-Italic.ttf';
// Images
import tractiqLogoFull from './tractiq-logo-with-name.png';
import tractiqLogoLight from './tractiq-logo-light-with-name.png';
import tractiqLogo from './tractiq-logo.png';
import mapPin from './map-pin.png';
import marketSummaryIcon from './marketSummaryIcon.png'
import coverImage from './Cover_image.jpg'
import coverPinImage from './cover-pin.png'
import addressIconImage from './addressIcon.png';
import phoneIconImage from './phoneIcon.png';
import websiteIconImage from './websiteIcon.png'
import mapPinOpaque from './map-pin-opaque.png'

// TODO: try to document what is happening here
function toDataURL(url, trim = true) {
    return fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        let encoded;
                        if (trim) {
                            encoded = reader.result
                                .toString()
                                .replace(/^data:(.*,)?/, '');
                            if (encoded.length % 4 > 0) {
                                encoded += '='.repeat(4 - (encoded.length % 4));
                            }
                        } else {
                            encoded = reader.result;
                        }
                        resolve(encoded);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                }),
        );
}

export const barlowCondensedBoldFont = toDataURL(bcBold);
export const montserratRegularFont = toDataURL(msRegular);
export const montserratBoldFont = toDataURL(msBold);
export const montserratSemiBoldFont = toDataURL(msSemiBold);
export const sourceSansProRegularFont = toDataURL(sspRegular);
export const sourceSansProBoldFont = toDataURL(sspBold);
export const sourceSansProSemiBoldFont = toDataURL(sspSemiBold);
export const sourceSansProItalicFont = toDataURL(sspItalic);

export const tractiqLogoFullImage = toDataURL(tractiqLogoFull, false);
export const tractiqLogoLightImage = toDataURL(tractiqLogoLight, false);
export const tractiqLogoImage = toDataURL(tractiqLogo, false);
export const mapPinImage = toDataURL(mapPin, false);
export const marketSummaryIconImage = toDataURL(marketSummaryIcon, false);
export const cover = toDataURL(coverImage, false);
export const coverPin = toDataURL(coverPinImage, false);

export const websiteIcon = toDataURL(websiteIconImage, false);
export const phoneIcon = toDataURL(phoneIconImage, false);
export const addressIcon = toDataURL(addressIconImage, false);
export const mapPinImageOpaque = toDataURL(mapPinOpaque, false);
