import ReportSwitcher from './ReportSwitcher';
import Location from './Location';
import { SelfStoragePointHeaderInfo } from '../types';

type Props = {
    locationType?: SelfStoragePointHeaderInfo | undefined;
    children?: React.ReactNode;
};

const ReportSwitcherAndLocationSection = ({
    locationType,
    children,
}: Props) => {
    return (
        <div className="container-sm px-0">
            <div className="d-flex flex-row justify-content-between position-relative flex-wrap">
                <ReportSwitcher />
                {children}
            </div>
            <Location locationType={locationType} />
        </div>
    );
};

export default ReportSwitcherAndLocationSection;
