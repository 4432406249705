import ValueFormat from '../../../enums/ValueFormat';
import { ExportSettings, SectionVisualizations } from '../../../types';

export const PDF_EXPORT_SETTINGS: ExportSettings = {
    documentProperties: {
        title: 'Storage Site Report',
        alternativeTitle: 'Storage Site Report',
        author: 'Social Explorer',
        keywords: 'tractiq, selfstorage, social explorer, storage',
        creator: 'Social Explorer',
    },
    documentSubject: 'TractIQ Storage Site Report',
    exportVisualizations: [],
    newPagesOnVisualizations: [
        'allUnits',
        'nonClimateControlled',
        'smallNCC',
        'mediumNCC',
        'largeNCC',
        'groundFloorNCC',
        'elevatorNCC',
    ],
};

export const REPORT_SECTIONS: SectionVisualizations[] = [
    [
        {
            id: 'marketSummary',
            header: '',
            title: '',
            labels: [
                {
                    title: `Square Footage per Capita`,
                    variable: 'rent_sqft_per_capita',
                    format: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
            ],
            type: 'table',
            valueFormat: ValueFormat.FORMAT_NONE,
        },
        {
            id: 'facilitiesInfo',
            header: '',
            title: '',
            labels: [
                {
                    title: `Number of Current Facilities`,
                    variable: 'facility_operating',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                    isNumberOfFacilities: true,
                },
                {
                    title: `Total Gross Square Footage`,
                    variable: 'gross_sqft',
                    format: ValueFormat.FORMAT_NUMBER,
                },
                {
                    title: `Total Net Rentable Square Footage`,
                    variable: 'rent_sqft',
                    format: ValueFormat.FORMAT_NUMBER,
                },
                {
                    title: `% Sq Ft that is Drive Up`,
                    variable: 'rent_sqft_driveup_percentage',
                    format: ValueFormat.FORMAT_PERCENT_2_DECIMAL,
                },
                {
                    title: `% Current Facilities Offering Climate Control`,
                    variable: 'facility_operating_has_cc_percentage',
                    format: ValueFormat.FORMAT_PERCENT_2_DECIMAL,
                },
            ],
            type: 'table',
            valueFormat: ValueFormat.FORMAT_NONE,
        },
        {
            id: 'populationInfo',
            header: '',
            title: '',
            labels: [
                {
                    title: `Population`,
                    variable: 'population',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                },
                {
                    title: `Population Density`,
                    variable: 'population_density',
                    format: ValueFormat.FORMAT_NUMBER_1_DECIMAL,
                },
                {
                    title: `Median Household Income`,
                    variable: 'median_household_income',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                },
                {
                    title: `Average Household Income`,
                    variable: 'average_household_income',
                    format: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    title: `Number of Households`,
                    variable: 'households',
                    format: ValueFormat.FORMAT_NUMBER,
                },
                {
                    title: `% Renters`,
                    variable: 'renters_percentage',
                    format: ValueFormat.FORMAT_PERCENT_1_DECIMAL,
                },
                {
                    title: `% Homeowners`,
                    variable: 'homeowners_percentage',
                    format: ValueFormat.FORMAT_PERCENT_1_DECIMAL,
                },
                {
                    title: `Sq. Ft. per Household`,
                    variable: 'sqft_per_household',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                },
            ],
            type: 'table',
            valueFormat: ValueFormat.FORMAT_NONE,
        },
        {
            id: 'demographyInfo',
            header: '',
            title: 'Projections (incoming supply & demand)',
            labels: [
                {
                    title: `New Facilities in Development`,
                    variable: 'facility_construction',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                },
                {
                    title: `Gross Sq. Ft. of New Facilities`,
                    variable: 'gross_sqft_construction',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                },
                {
                    title: `Net Sq. Ft. of New Facilities`,
                    variable: 'rent_sqft_construction',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                },
            ],
            type: 'table',
            valueFormat: ValueFormat.FORMAT_NONE,
        },
        {
            id: 'projectionsInfo',
            header: '',
            title: '',
            groups: ['Based on Census', 'Based on Housing'],
            labels: [
                {
                    title: `Population Growth Projections`,
                    variable: 'population_projected_census',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                    group: 'Based on Census',
                },
                {
                    title: `Population Growth Projections`,
                    variable: 'population_projected_housing',
                    format: ValueFormat.FORMAT_NUMBER_NO_DECIMAL,
                    group: 'Based on Housing',
                },
                {
                    title: `Population Growth Projections (%)`,
                    variable: 'population_projected_housing_percentage',
                    format: ValueFormat.FORMAT_PERCENT_1_DECIMAL,
                    group: 'Based on Housing',
                },
                {
                    title: `Population Growth Projections (%)`,
                    variable: 'population_projected_census_percentage',
                    format: ValueFormat.FORMAT_PERCENT_1_DECIMAL,
                    group: 'Based on Census',
                },
                {
                    title: `Projected Sq. Ft. per Capita`,
                    variable: 'sqft_per_capita_projected_census',
                    format: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                    group: 'Based on Census',
                },
                {
                    title: `Projected Sq. Ft. per Capita`,
                    variable: 'sqft_per_capita_projected_housing',
                    format: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                    group: 'Based on Housing',
                },
            ],
            type: 'groupedTable',
            valueFormat: ValueFormat.FORMAT_NONE,
        },
        {
            id: 'avgPrices',
            header: '',
            title: 'Average Market Rate per Square Foot',
            variable: 'avg_market_rates',
            columns: [
                {
                    title: `Unit Size`,
                    variable: 'unitSize',
                    format: ValueFormat.FORMAT_NONE,
                },
                {
                    title: `Non-Climate Controlled`,
                    variable: 'amb',
                    format: ValueFormat.FORMAT_CURRENCY_2_DECIMAL,
                },
                {
                    title: `Climate Controlled`,
                    variable: 'cc',
                    format: ValueFormat.FORMAT_CURRENCY_2_DECIMAL,
                },
            ],
            type: 'listTable',
            valueFormat: ValueFormat.FORMAT_NONE,
        },
    ],
];

export const PDF_REPORT: SectionVisualizations[] = [
    [
        {
            id: 'A10064',
            header: '',
            title: ``,
            labels: [
                {
                    variable: 'rent_sqft_per_capita',
                    title: `Current Square Foot per Capita`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'sqft_per_capita_projected_census',
                    title: `Projected (5 years) Square Foot per Capita`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
    ],
    [
        {
            id: 'T001',
            header: 'Population',
            title: ``,
            labels: [
                {
                    variable: 'population',
                    title: `Current Total Population `,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_density',
                    title: `Current Population Density `,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_projected_census',
                    title: `Projected (5 years) Total Population `,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                    calculationColumn: 'population',
                },
                {
                    variable: 'population_density_projected',
                    title: `Projected (5 years) Population Density `,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                    calculationColumn: 'population_density',
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T002',
            header: 'Households',
            title: ``,
            labels: [
                {
                    variable: 'households',
                    title: `Current Households `,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'households_projected',
                    title: `Projected (5 years) Households`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                    calculationColumn: 'households',
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T004',
            header: 'Income',
            title: ``,
            labels: [
                {
                    variable: 'median_household_income',
                    title: `Current Median Household Income`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'average_household_income',
                    title: `Current Average Household Income`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'median_household_income_projected',
                    title: `Projected (5 years) Median Household Income`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                    calculationColumn: 'median_household_income',
                },
                {
                    variable: 'average_household_income_projected',
                    title: `Projected (5 years) Average Household Income`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                    calculationColumn: 'average_household_income',
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T022',
            header: 'Median Rent',
            title: ``,
            labels: [
                {
                    variable: 'median_rent_2020',
                    title: `2020 Median Rent`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'median_rent',
                    title: `Current Median Rent`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                    calculationColumn: 'median_rent_2020',
                },
                {
                    variable: 'median_rent_projected',
                    title: `Projected (5 years) Median Rent`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                    calculationColumn: 'median_rent_2020',
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
    ],
    [
        {
            id: 'T005',
            header: 'Daytime Demographics',
            title: ``,
            labels: [
                {
                    variable: 'population_daytime',
                    title: `Daytime Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'employees',
                    title: `Employees, Total`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'total_retail_sales',
                    title: `Total Retail Sales`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T010',
            header: `Current Population by Age`,
            title: ``,
            labels: [
                {
                    variable: 'population_median_age',
                    title: `Current Population, Median Age`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_age_to_5yrs',
                    title: `Current Population Aged 0 to 5 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_6_to_11yrs',
                    title: `Current Population Aged 6 to 11 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_12_to_17yrs',
                    title: `Current Population Aged 12 to 17 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_18_to_24yrs',
                    title: `Current Population Aged 18 to 24 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_25_to_34yrs',
                    title: `Current Population Aged 25 to 34 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_35_to_44yrs',
                    title: `Current Population Aged 35 to 44 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_45_to_54yrs',
                    title: `Current Population Aged 45 to 54 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_55_to_64yrs',
                    title: `Current Population Aged 55 to 64 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_65_to_74yrs',
                    title: `Current Population Aged 65 to 74 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_75_to_84yrs',
                    title: `Current Population Aged 75 to 84 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_age_above_85yrs',
                    title: `Current Population Aged 85 Years and Older`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T006',
            header: 'Population by Race',
            title: ``,
            labels: [
                {
                    variable: 'population_race_white',
                    title: `Current White Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_black',
                    title: `Current Black Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_asian',
                    title: `Current Asian Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_native',
                    title: `Current American Indian and Alaska Native Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_other',
                    title: `Current Other Race Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_multi',
                    title: `Current Two or More Races Population:`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_white_projected',
                    title: `Projected (5 years) White Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_black_projected',
                    title: `Projected (5 years) Black Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_asian_projected',
                    title: `Projected (5 years) Asian Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_native_projected',
                    title: `Projected (5 years) American Indian and Alaska Native Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_other_projected',
                    title: `Projected (5 years) Other Race Population: Alone`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_race_multi_projected',
                    title: `Projected (5 years) Two or More Races Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T007',
            header: 'Population by Ethnicity',
            title: ``,
            labels: [
                {
                    variable: 'population_ethnic_hispanic',
                    title: `Current Hispanic Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_ethnic_white_nonhispanic',
                    title: `Current White Non-Hispanic Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_ethnic_hispanic_projected',
                    title: `Projected (5 years) Hispanic Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_ethnic_white_nonhispanic_projected',
                    title: `Projected (5 years) White Non-Hispanic Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T008',
            header: 'Population by Sex',
            title: ``,
            labels: [
                {
                    variable: 'population_male',
                    title: `Current Male`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female',
                    title: `Current Female`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_projected',
                    title: `Projected (5 years) Male`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_projected',
                    title: `Projected (5 years) Female`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T009',
            header: 'Population by Sex by Age',
            title: ``,
            labels: [
                {
                    variable: 'population_male_age_to_5yrs',
                    title: `Current Males Aged 0 to 5 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_6_to_11yrs',
                    title: `Current Males Aged 6 to 11 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_12_to_17yrs',
                    title: `Current Males Aged 12 to17 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_18_to_24yrs',
                    title: `Current Males Aged 18 to 24 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_25_to_34yrs',
                    title: `Current Males Aged 25 to 34 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_35_to_44yrs',
                    title: `Current Males Aged 35 to 44 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_45_to_54yrs',
                    title: `Current Males Aged 45 to 54 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_55_to_64yrs',
                    title: `Current Males Aged 55 to 64 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_65_to_74yrs',
                    title: `Current Males Aged 65 to 74 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_75_to_84yrs',
                    title: `Current Males Aged 75 to 84 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_male_age_above_85yrs',
                    title: `Current Males Aged 85 Years and Older`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_to_5yrs',
                    title: `Current Females Aged 0 to 5 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_6_to_11yrs',
                    title: `Current Females Aged 6 to 11 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_12_to_17yrs',
                    title: `Current Females Aged 12 to 17 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_18_to_24yrs',
                    title: `Current Females Aged 18 to 24 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_25_to_34yrs',
                    title: `Current Females Aged 25 to 34 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_35_to_44yrs',
                    title: `Current Females Aged 35 to 44 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_45_to_54yrs',
                    title: `Current Females Aged 45 to 54 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_55_to_64yrs',
                    title: `Current Females Aged 55 to 64 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_65_to_74yrs',
                    title: `Current Females Aged 65 to 74 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_75_to_84yrs',
                    title: `Current Females Aged 75 to 84 Years`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_female_age_above_85yrs',
                    title: `Current Females Aged 85 Years and Older`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T001',
            header: 'Population & Growth',
            title: ``,
            labels: [
                {
                    variable: 'population',
                    title: `Current Total Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_density',
                    title: `Current Population Density`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_2020_current_growth',
                    title: `2020-Current Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_2020_current_avg_annual_growth',
                    title: `2020-Current Average Annual Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_projected_census',
                    title: `Projected (5 years) Total Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_density_projected',
                    title: `Projected (5 years) Population Density`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_growth_projected',
                    title: `Projected (5 years) Population Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_avg_annual_growth_projected',
                    title: `Projected (5 years) Average Annual Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'population_2020',
                    title: `2020 Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_2010',
                    title: `2010 Total Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'population_2000',
                    title: `2000 Population`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T002',
            header: 'Households & Growth',
            title: ``,
            labels: [
                {
                    variable: 'households',
                    title: `Current Households`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'households_2020_current_growth',
                    title: `2020-Current Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'households_2020_current_avg_annual_growth',
                    title: `2020-Current Average Annual Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'households_projected',
                    title: `Projected (5 years) Households`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'households_growth_projected',
                    title: `Current-Projected (5 years) Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'households_avg_annual_growth_projected',
                    title: `Current-Projected (5 years) Average Annual Growth`,
                    valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
                },
                {
                    variable: 'households_2020',
                    title: `2020 Households`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T003',
            header: 'Families',
            title: ``,
            labels: [
                {
                    variable: 'families',
                    title: `Current Families`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
                {
                    variable: 'families_projected',
                    title: `Projected (5 years) Families`,
                    valueFormat: ValueFormat.FORMAT_NUMBER,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
        {
            id: 'T004',
            header: 'Income',
            title: ``,
            labels: [
                {
                    variable: 'total_household_income',
                    title: `Current Aggregate Household Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
                {
                    variable: 'average_household_income',
                    title: `Current Average Household Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
                {
                    variable: 'median_household_income',
                    title: `Current Median Household Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
                {
                    variable: 'income_per_capita',
                    title: `Current Per Capita Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
                {
                    variable: 'total_household_income_projected',
                    title: `Projected (5 years) Aggregate Household Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
                {
                    variable: 'average_household_income_projected',
                    title: `Projected (5 years) Average Household Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
                {
                    variable: 'median_household_income_projected',
                    title: `Projected (5 years) Median Household Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
                {
                    variable: 'income_per_capita_projected',
                    title: `Projected (5 years) Per Capita Income`,
                    valueFormat: ValueFormat.FORMAT_CURRENCY_0_DECIMAL,
                },
            ],
            type: 'label',
            valueFormat: ValueFormat.FORMAT_NUMBER_2_DECIMAL,
        },
    ],
];
