import { SiteAnalysis, SectionVisualizations } from '../types';
import Section from './Section';
import SectionsHeader from './SectionsHeader';

type Props = {
    selectedSelection: number | null;
    reportSections: SectionVisualizations[];
    data: SiteAnalysis[];
};

const Sections = ({ selectedSelection, reportSections, data }: Props) => {
    return (
        <div>
            {!selectedSelection && <SectionsHeader />}
            {reportSections.map((section, index) => {
                return (
                    <Section
                        key={index}
                        selectedSelection={selectedSelection}
                        section={section}
                        data={data}
                    />
                );
            })}
        </div>
    );
};

export default Sections;
