import { PdfReportTypographyClass } from '../types';
import {
    WHITE,
    GRAY50,
    GRAY70,
    GRAY90,
    BLACK,
    BLUE_DARK,
} from './pdfColorsRGB';

export const H1: PdfReportTypographyClass = {
    color: [...WHITE],
    size: 55,
    fontName: 'BarlowCondensed',
    fontStyle: 'bold',
};

export const H1_SMALLER: PdfReportTypographyClass = {
    color: [...WHITE],
    size: 45,
    fontName: 'BarlowCondensed',
    fontStyle: 'bold',
};

export const H2: PdfReportTypographyClass = {
    color: [...GRAY90],
    size: 18,
    fontName: 'Montserrat',
    fontStyle: 'bold',
};

export const H3: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 14,
    fontName: 'Montserrat',
    fontStyle: 'bold',
};

export const H4: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 11.5,
    fontName: 'Montserrat',
    fontStyle: 'bold',
};

export const H5: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 8,
    fontName: 'Montserrat',
    fontStyle: 'semibold',
};

export const H6: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 8,
    fontName: 'Montserrat',
    fontStyle: 'regular',
};
export const ADDRESS: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 11.5,
    fontName: 'Montserrat',
    fontStyle: 'semibold',
};

export const SUBTITLE: PdfReportTypographyClass = {
    color: [...WHITE],
    size: 10,
    fontName: 'Montserrat',
    fontStyle: 'regular',
};

export const LABEL: PdfReportTypographyClass = {
    color: [...GRAY70],
    size: 10,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const VALUE: PdfReportTypographyClass = {
    color: [...GRAY90],
    size: 10,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const VALUE_STRONG: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 10,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const VALUE_STRONG_WHITE: PdfReportTypographyClass = {
    ...VALUE_STRONG,
    color: [...WHITE],
};

export const SMALL_TEXT: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const SMALL_TEXT_70_SB: PdfReportTypographyClass = {
    color: [...GRAY70],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};


export const SMALL_TEXT_DARK: PdfReportTypographyClass = {
    color: [...GRAY90],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const SMALL_TEXT_DARK_BOLD: PdfReportTypographyClass = {
    color: [...GRAY90],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const TINY_TEXT: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const TINY_TEXT_70: PdfReportTypographyClass = {
    color: [...GRAY70],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const TINY_TEXT_DARK: PdfReportTypographyClass = {
    color: [...GRAY90],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const TINY_TEXT_LIGHT: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const TINY_TEXT_BOLD_LIGHT: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const TINY_TEXT_ITALIC: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'italic',
};

export const TINY_TEXT_ITALIC_LIGHT: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'italic',
};

export const TINY_TEXT_BOLD_WHITE: PdfReportTypographyClass = {
    color: [...WHITE],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const TINY_TEXT_BOLD_BLACK: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 8,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const MICRO_TEXT: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 7,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const MICRO_TEXT_LIGHT: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 7,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const MICRO_TEXT_BOLD_LIGHT: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 7,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const MICRO_TEXT_BOLD_WHITE: PdfReportTypographyClass = {
    color: [...WHITE],
    size: 7,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const MICRO_TEXT_BOLD_BLACK: PdfReportTypographyClass = {
    color: [...WHITE],
    size: 7,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const SMALL_TEXT_BOLD: PdfReportTypographyClass = {
    color: [...GRAY50],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const SMALL_TEXT_BOLD_WHITE: PdfReportTypographyClass = {
    color: [...WHITE],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const SMALL_TEXT_BOLD_BLACK: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};
export const SMALL_TEXT_BLACK: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const SELECTION: PdfReportTypographyClass = {
    color: [...GRAY90],
    size: 10,
    fontName: 'SourceSansPro',
    fontStyle: 'semibold',
};

export const LIST_ITEM: PdfReportTypographyClass = {
    color: [...GRAY90],
    size: 11.5,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};

export const H5_TAB: PdfReportTypographyClass = {
    ...H5,
    color: [...GRAY90],
    size: 9,
};

export const H6_TAB: PdfReportTypographyClass = {
    ...VALUE,
    color: [...GRAY90],
    size: 9,
};

export const VALUE_TAB: PdfReportTypographyClass = {
    ...VALUE,
    color: [...GRAY90],
    size: 8,
};

export const HEADER_TAB: PdfReportTypographyClass = {
    ...VALUE,
    color: [...WHITE],
    size: 8,
};

export const TABLE_TITLE: PdfReportTypographyClass = {
    color: [...BLACK],
    size: 9,
    fontName: 'Montserrat',
    fontStyle: 'bold',
};

export const LINK: PdfReportTypographyClass = {
    color: [...BLUE_DARK],
    size: 9,
    fontName: 'SourceSansPro',
    fontStyle: 'regular',
};
